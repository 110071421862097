import React, { useEffect ,useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Circles } from "react-loader-spinner";


const RedirectionMangements = () => {

  const [loading, setLoading] = useState(true); 

    const redirectPath = window.location.pathname;
    const navigate = useNavigate();


  useEffect(() => {

    const redirectionMap = {
      "/your-task-list/completed": { path: "/your-task-list", state: { id: "completed-task" } },
      "/your-task-list/in-progress": { path: "/your-task-list", state: { id: "in-progress" } },
      "/your-task-list/in-closed": { path: "/your-task-list", state: { id: "in-closed" } },
      "/hustlr-jobs/completed-jobs": { path: "/hustlr-jobs", state: { id: "completed-jobs" } },
      "/hustlr-jobs/closed-jobs": { path: "/hustlr-jobs", state: { id: "closed-jobs" } },
      "/hustlr-jobs/in-progress": { path: "/hustlr-jobs", state: { id: "upcoming" } },
      "/hustlr-jobs/cancelled-jobs": { path: "/hustlr-jobs", state: { id: "cancelled-jobs" } },
      // "/home/incoming-jobs": { path: "/home", state: { id: "incoming-jobs" } },
      // "/home/interested-jobs": { path: "/home", state: { id: "interested-jobs" } },
      // "/home/saved-jobs": { path: "/home", state: { id:"saved-jobs" } },
      // "/home/new-jobs": { path: "/home", state: { id: "new-jobs" } },

      "/home/incoming-jobs": { path: "/hustlerhome", state: { id: "incoming-jobs" } },
      "/home/interested-jobs": { path: "/hustlerhome", state: { id: "interested-jobs" } },
      "/home/saved-jobs": { path: "/hustlerhome", state: { id:"saved-jobs" } },
      "/home/new-jobs": { path: "/hustlerhome", state: { id: "new-jobs" } },

    };


    const handleRedirection = () => {
      const redirectConfig = redirectionMap[redirectPath];
      if (redirectConfig) {
        navigate(redirectConfig.path, { state: redirectConfig.state });
        sessionStorage.removeItem("redirectPath");
      }
      setLoading(false); // End loader as soon as navigation is processed
    };

    handleRedirection();
  }, [redirectPath, navigate]);


  return (
    <div>
      {loading ? (
        <div className="loaderHolder">
          <div className="loaderMain">
            <Circles
              height="80"
              width="80"
              color="#ffffff"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      ) : (
        <div className="loaderHolder">
          <div className="loaderMain">
            <Circles
              height="80"
              width="80"
              color="#ffffff"
              ariaLabel="circles-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default RedirectionMangements

import React,{useState,useEffect} from 'react'
import Select from "react-select";
import { authAxios } from "../services/config";


const SearchHusterBar = ({setSearchData}) => {
      const [selectedService, setSelectedService] = useState("");
        const [provinceList, setprovinceList] = useState([]);
          const [selectedCity, setSelectedCity] = useState(null);
            const [cityList, setcityList] = useState([]);
            const [selectedSort, setSelectedSort] = useState("");
          
            const [selectedProvince, setselectedProvince] = useState(null);
            const [stateCode, setStateCode] = useState("");
          
      const handleSelectProvince = (e) => {
        if (e) {
          setStateCode(e?.value);
          setselectedProvince(e);
          setSelectedCity([]);
        } else {
          setcityList([]);
          setSelectedCity([]);
          setStateCode("");
          setselectedProvince([]);
        }
      };

        // const handleSearchHustlers = async (data, number) => {
        //   // setLoading(true);
        //   // https://hustlenode.makemagento.com/api/search/search-and-sort-hustlers?page=1&limit=6&sort=

        //   const previousData = {
        //     location:"Kamloops",
        //     province: "British Columbia",
        //     query: ""};
      
      
        //   try {
        //     const response = await authAxios().post(
        //       `/search/search-and-sort-hustlers?page=${number || 1}&limit=${10}`,
        //       previousData
        //     );
        //     const resData = response?.data;
        //       console.log(resData,"testing")
        //     // setLoading(false);
        //     if (resData.status === 1) {
        //       // sethustler(resData?.data?.hustlers);
        //       // settotalPosts(resData?.data?.totalCount);
        //     } else {
        //       // toast.error(resData?.message);
        //     }
        //   } catch (error) {
        //     // setLoading(false);
        //     // toast.error(error?.response?.data?.message);
        //   }
        // };
      
      

      const handleButtonSearch = async () => {

        const payload = {
          query: selectedService,
          location: selectedCity?.label,
          province: selectedProvince?.label,
        };

        console.log(payload,"payload")
        setSearchData({
          query: selectedService,
          location: selectedCity?.label,
          province: selectedProvince?.label,
        })
        // handleSearchHustlers(payload, 1);
        // setongoingPagination("all-searching");
        // setcurrentPage(1);
      };
    
    
        useEffect(() => {
        //   checkFavoriteHustler();
          // setprovinceList(
          //   State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
          //     value: isoCode,
          //     label: name,
          //   }))
          // );
          setprovinceList(
            [ { value: "BC", label: "British Columbia" },
              {value: "SK",label: "Saskatchewan"}
           ]
            );
        }, []);
      
        useEffect(() => {
          setcityList([])
          if(stateCode=="BC"){
              setcityList(
                [ {"value": "Kamloops", "label": "Kamloops"},
                  {"value": "Kelowna","label": "Kelowna"},
                  {"value": "Peachland","label": "Peachland"},
                  {"value": "Penticton","label": "Penticton"},
                  {"value": "Vernon","label": "Vernon"},
                  {"value": "West Kelowna","label": "West Kelowna"},
                ]
              )
            }
            else{
          
              setcityList(
                [ {value: "Saskatoon",label: "Saskatoon"}
                ]
              )
          
          
            }
            if (stateCode) {
            // setcityList(
            //   City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
            //     value: name,
            //     label: name,
            //   }))
            // );
          }
        }, [stateCode]);
      
    
  return (
    <div>
                  <div className="director--search--hustler hustler--home--page">
            <div className="custom--container">
              <div className="container--filter">
                <div
                  className="filter--data--search"
                  style={{ maxWidth: "100%" }}
                >
                  <div className="filter--search--col">
                    <div className="search--bar--banner flex sm-flex-col sm-justify-start responsive--search--bar">
                      <input
                        required
                        placeholder="I need help with..."
                        className="need--select"
                        value={selectedService}
                        onChange={(e) => setSelectedService(e.target.value)}
                      />

                      <div className="city--zip">
                        <Select
                          options={provinceList}
                          isClearable={false}
                          className="select--custom province--select"
                          value={selectedProvince}
                          placeholder={`Select Province`}
                          onChange={handleSelectProvince}
                          // isSearchable={false}
                        />
                      </div>
                      <div className="city--zip">
                        <Select
                          options={selectedProvince ? cityList : []}
                          className="select--custom"
                          value={selectedCity}
                          placeholder={`Select City`}
                          onChange={(option) => setSelectedCity(option)}
                          // isSearchable={false}
                        />
                      </div>

                      {selectedService?.length > 0 || selectedProvince?.label ? (
                        <button
                          className="btn btn-dark submit--need"
                          onClick={handleButtonSearch}
                        >
                          Search Hustlr
                        </button>
                      ) : (
                        <button
                          className="btn btn-dark submit--need"
                          // style={{ cursor: "not-allowed" }}
                          // onClick={() => fetchallUsers(1)}
                          // handleSearchHustlers
                          onClick={handleButtonSearch}

                          // onClick={() => handleSearchHustlers()}

                        >
                          Search Hustlr
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

      
    </div>
  )
}

export default SearchHusterBar

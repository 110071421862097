import React, { useEffect, useState } from "react";
import { FaDollarSign } from "react-icons/fa";
import HustlerReviews from "./HustlerReviews";
import { BsChatRightText } from "react-icons/bs";
import { Link, useParams, useNavigate } from "react-router-dom";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import {
  BudgetFormat,

  displayBudget,

  handleDecimalNumber,

  serviceFeePercentage,

} from "../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { setParticipantDetails } from "../../Redux/Reducers/chatSlice";
import AddReviews from "../../common/AddReviews";
import BookHustlerPopup from "./BookHustlerPopup";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { useSocket } from "../../contexts/SocketContext";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentForm from "./PaymentForm";
import InterstedHustlerStart from "./InterstedHustlerStart";
// import BeforePaymentForm from "./BeforePaymentForm";

import DirectorCounterOfferModel from "./DirectorCounterOfferModel";
import DirectorFirstCounterOfferModel from "../../common/DirectorFirstCounterOfferModel";
import { setReportFormatDate } from "../../assets/Helper";
import { AiOutlineCalendar } from "react-icons/ai";
import ConfirmDeniedJobModel from "../../common/ConfirmDeniedJobModel";
import { IoClose } from "react-icons/io5";
import ProfileReviews from "../../common/ProfileReview";
import { useTour } from '../../contexts/TourContext';
import { ImPriceTag } from "react-icons/im";

const stripePromise = loadStripe(
  `${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`
);


const InterstedHustler = (props) => {
  const { currentStepIndex, isTourRunning, steps, nextStep, resetTour ,YesPopup} = useTour();

  const { setLoading } = props;
  const param = useParams();
  const { hustlerId, taskId, finalPrice } = param;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userDetail, setuserDetail] = useState([]);
  const [TaskDetail, setTaskDetail] = useState([]);
  const [showPaymentForm, setshowPaymentForm] = useState(false);
  const [BeforeShowPaymentForm, setBeforeShowPaymentForm] = useState(false);


  const [paymentIntentId, setPaymentIntentId] = useState(null);
  const [paymentAmount, setpaymentAmount] = useState("");
  const [finaltaskDetails, setfinaltaskDetails] = useState({
    budget: "",
    date: "",
  });
  const [proposalSend, setProposalSend] = useState([]);
  const [showdenieJobModel, setshowdenieJobModel] = useState(false);
  const [proposalStatus, setProposalStatus] = useState(false);
  const [showdirectorCounterOffermodel, setshowdirectorCounterOffermodel] =
    useState(false);
  const [allCounterOfferdetails, setallCounterOfferdetails] = useState([]);
  const [singleTask, setSingleTask] = useState({});
  const [incomingJobtaskId, setincomingJobtaskId] = useState("");
  const [firstTimeCounterOffer, setfirstTimeCounterOffer] = useState(false);
  const [counterOfferCancelledModal, setCounterOfferCancelledModal] =
    useState(false);
  const { _id } = useSelector((state) => state?.auth?.user);
  const socket = useSocket();

  const [showReviewModel, setshowReviewModel] = useState(false);
  const [showBookHustler, setshowBookHustler] = useState(false);
  const [stripeAccountId, setStripeAccountId] = useState("");
  const [comments, setcomments] = useState([]);


  const fetchHustler = async () => {
    setLoading(true);
    await authAxios()
      .get(`users/get-specific-user/${hustlerId}`)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          setuserDetail(resData?.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const handleViewCounterOffer = async () => {
    setSingleTask(TaskDetail.task);
    setincomingJobtaskId(TaskDetail.task._id);
    if(!isTourRunning){
    await authAxios()
      .get(`/task/get-counter-offer/${TaskDetail.task._id}`)
      .then((response) => {
        const resData = response.data;

        if (resData.status === 1) {
          // Check if there are any counter offers for the specified task and hustler
          const counterOfferData = resData.data.find(
            (item) => item.hustlerId === hustlerId
          );

          if (counterOfferData) {
            const hustlerCounterOffers = counterOfferData.counterOffers.filter(
              (offer) => offer.userType === "HUSTLER"
            );
            const DirectorCounterOffers = counterOfferData.counterOffers.filter(
              (offer) => offer.userType !== "HUSTLER"
            );

            const allOffers = {
              hustlerCounterOffers: hustlerCounterOffers,
              directorCounterOffers: DirectorCounterOffers,
            };

            setallCounterOfferdetails(allOffers);
            setshowdirectorCounterOffermodel(true);
          } else {
            // If no counter offers found for the specified hustler, set it to an empty array
            setallCounterOfferdetails([]);
          }
        } else {
          toast.error(resData.message);
        }
        //  setshowdirectorCounterOffermodel(true);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
    }
  };

  const fetchCounterOfferDetails = async () => {
    try {
      setLoading(true);
      const response = await authAxios().get(
        `/task/get-counter-offer/${taskId}`
      );
      const resData = response.data;

      if (resData.status === 1) {
        // Check if there are any counter offers for the specified task and hustler
        const counterOfferData = resData.data.find(
          (item) => item.hustlerId === hustlerId
        );


        if (counterOfferData) {
          setallCounterOfferdetails(counterOfferData.counterOffers);
        } else {
          // If no counter offers found for the specified hustler, set it to an empty array
          setallCounterOfferdetails([]);
        }
      } else {
        toast.error(resData.message);
      }
    } catch (error) {
      toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };


  const CounterOffer = async (data) => {
    setLoading(true);
    await authAxios()
      .post(`/task/counter-offer`, data)
      .then((response) => {
        const resData = response.data;
        setLoading(false);

        if (resData.status == 1) {
          socket.emit("counterOfferbyDirector", resData.data);
          setshowdirectorCounterOffermodel(false);
          setallCounterOfferdetails([]);
          toast.success(resData.message);
          setfirstTimeCounterOffer(false);
          fetchTask();

          fetchCounterOfferDetails();
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  const fetchallReview = async () => {
    setLoading(true);
    await authAxios()
      .get(`review/specific-user-reviews/${hustlerId}`)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          setcomments(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const handleCancelPopup = () => {
    setshowBookHustler(false);
  };

  const bookHustler = async (price) => {
    const payload = {
      hustlerId: hustlerId,
      taskId: taskId,
      finalPrice: price || finalPrice,
    };


    setLoading(true);
    try {
      const response = await authAxios().post(
        `/bookhustler/book-hustler`,
        payload
      );
      const resData = response.data;
      if (resData.status === 1) {
        socket.emit("hustlerBooked", resData.data);
        toast.success("Hustlr booked successfully!");

        await authAxios()
          .post("/stripe/change-payment-status", {
            taskId: taskId,
          })
          .then((response) => {
            const resData = response.data;
            if (resData.status == 1) {
              socket.emit("paymentToHustler", taskId);
              setLoading(false);
              navigate("/your-task-list", { state: { id: "in-progress" } });
              console.log("/director-thanks2")
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error.response.data.message);
          });
        navigate("/your-task-list", { state: { id: "in-progress" } });


        // console.log("/director-thanks33")
      } else {
        toast.error(resData.message);
      }
    } catch (error) {
      toast.error("Failed to book hustlr. Please try again.");
    } finally {
      setLoading(false);
    }
  };



  const fetchLoggedInUserDetails = async () => {
    await authAxios()
      .get(`/users/get-specific-user/${_id}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setStripeAccountId(resData.data.stripeAccountId)
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    if(!isTourRunning){
      fetchLoggedInUserDetails()

    }
  }, [])


  const handleBeforeSubmit = (item) => {
    setfinaltaskDetails((prev) => ({
      ...prev,
      budget: paymentAmount,
    }));
    setBeforeShowPaymentForm(true)

  }


  const handleSubmit = async (pricing) => {
    // handleStripeAccountConnect(pricing);

    // console.log("22")
    setLoading(true)
    await authAxios()
      .get(`/stripe/check-stripe-account`)
      .then(async (response) => {
        if (response.data && response.data.status === 1) {
          if (!TaskDetail.task.oneTimePayment) {
            await handleRecurringPayment(pricing, response.data.data);
            setLoading(false)
          } else {
            await handleOneTimePayment(pricing, response.data.data);
            setLoading(false)
          }
        } else {
          setLoading(false)
        }
      }
      )
      .catch((error) => {
        setLoading(false)
        if (error.response.data.status === 0) {
          handleStripeAccountConnect(pricing);
        } else {
          toast.error(error.response.data.message);
        }
      });
  };



  const handleStripeAccountConnect = async (pricing) => {
    setLoading(true)
    await authAxios()
      .post(`/stripe/create-customer-account`)
      // .post(`/stripe/create-customer-accounts`)

      .then(async (response) => {
        const resData = response.data;
        console.log(resData,"resData22")
        if (resData.status == 1) {
          setLoading(false)

          if (resData && resData.status === 1) {
            if (!TaskDetail.task.oneTimePayment) {
              await handleRecurringPayment(pricing, resData.data.id);
            } else {
              await handleOneTimePayment(pricing, resData.data.id);
            }
          }
        } else {
          setLoading(false)
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };


  const handleRecurringPayment = async (pricing, stripeAccountId) => {

    const totalPayment = (pricing * (1 + serviceFeePercentage)).toFixed(2) * 100 || (paymentAmount * (1 + serviceFeePercentage)).toFixed(2) * 100
    const meta = `${TaskDetail.task._id}-${TaskDetail.task.user}`;
    const metadata = {
      subscription_details: meta
    };

    try {
      const productID = process.env.REACT_APP_PRODUCT_ID;
      const stripeItems = [{
        price_data: {
          currency: "CAD",
          product: productID,
          recurring: {
            interval: TaskDetail.task.subscriptionInterval.toLowerCase(),
            interval_count: 1
          },
          unit_amount_decimal: totalPayment
        }
      }];

      const subscriptionResponse = await authAxios().post(`/stripe/create-subscription`, {
        customer: stripeAccountId,
        items: stripeItems,
        metadata: metadata
      });
      const subscriptionData = subscriptionResponse.data;

      if (subscriptionData.status === 1) {
        setfinaltaskDetails((prev) => ({
          ...prev,
          budget: typeof pricing === "number" ? pricing : paymentAmount,
        }));
        setPaymentIntentId(subscriptionData.data.latest_invoice.payment_intent.client_secret);
        setLoading(false);
        setshowPaymentForm(true);
      } else {
        toast.error(subscriptionData.message);
      }
    } catch (error) {
      console.error('Failed to create subscription:', error);
      toast.error(`Failed to create subscription: ${error.response && error.response.data.message ? error.response.data.message : "Network error"}`);
    }
  };

  // Function to handle one-time payment
  const handleOneTimePayment = async (pricing) => {
    // alert("hi1")
    // console.log(serviceFeePercentage,"serviceFeePercentage")
    // console.log(pricing,"pricing")
    // console.log(paymentAmount,"paymentAmount")


    const totalPayment = ((pricing * (1 + serviceFeePercentage)).toFixed(2)) * 100 || ((paymentAmount * (1 + serviceFeePercentage)).toFixed(2)) * 100
   
    // const totalPayment1 = pricing
    // ? Number((pricing * (1 + serviceFeePercentage)).toFixed(2)) * 100
    // : Number((paymentAmount * (1 + serviceFeePercentage)).toFixed(2)) * 100;

    // console.log(totalPayment.toFixed(2),"totalPayment")
    // console.log(totalPayment,"totalPayment")

    // console.log(totalPayment1,"totalPayment1")

       console.log(totalPayment % 1,"totalPayment1")

    const amountTotal = totalPayment % 1 >= 0.5 
    ? Math.ceil(totalPayment) // Round up if decimal part >= 0.5
    : Math.floor(totalPayment); // Round down if decimal part < 0.5
  
  //  console.log(amountTotal,"amountTotal")
   
    try {

      const paymentIntentResponse = await authAxios().
      post(`/stripe/create-payment-intent`,

        // post(`/stripe/create-payment-intents`,

         {
        amount: amountTotal,
        currency: "cad",
        transferGroup: `${hustlerId}-${taskId}-${totalPayment}`
      });

      const paymentIntentResData = paymentIntentResponse.data;

      if (paymentIntentResData.status === 1) {
        setfinaltaskDetails((prev) => ({
          ...prev,
          budget: typeof pricing === "number" ? pricing : paymentAmount,
        }));
        setPaymentIntentId(paymentIntentResData.data.client_secret);
        setLoading(false);
        setshowPaymentForm(true);
        setshowBookHustler(false);
      } else {
        toast.error(paymentIntentResData.message);
      }
    } catch (error) {
      toast.error(`Failed to create payment intent: ${error.response && error.response.data.message ? error.response.data.message : "Network error"}`);
    }
  };

  const checkHustlerCounterOffer = () => {
    const hustlerCounterOffers = allCounterOfferdetails?.filter(
      (offer) => offer?.userType === "HUSTLER"
    );
    if (hustlerCounterOffers.length > 0) {
      return false
    } else {
      return true
    }
  }

  const fetchHustlerAppliedTaskDetail = async () => {
    setLoading(true);
    await authAxios()
      .get(`/taskproposal/get-single-proposal/${hustlerId}/${taskId}`)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          setTaskDetail(resData?.data[0]);
          setpaymentAmount(resData.data[0].offer);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const fetchTask = async () => {
    setLoading(true);
    await authAxios()
      .get(`task/get-specific-task/${taskId}`)
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          // setpaymentAmount(resData.data.budget);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const handleAcceptcounterOffer = async () => {
    const pricing = allCounterOfferdetails?.hustlerCounterOffers[0]?.offerPrice;
    setpaymentAmount(pricing);
    handleSubmit(pricing);
    setshowdirectorCounterOffermodel(false);
    fetchCounterOfferDetails();
  };

  const handleCounterOffer = () => {
    setfirstTimeCounterOffer(true);
  };

  const handleDeniedJob = async (data) => {
    setshowdirectorCounterOffermodel(false);
    setallCounterOfferdetails([]);
    setshowdenieJobModel(true);

    if (showdenieJobModel == true) {
      await authAxios()
        .post(`/taskproposal/cancel/${TaskDetail?._id}`, { comment: data })
        .then((response) => {
          const resData = response.data;
          if (resData.status == 1) {
            socket.emit("cancelledProposalByDirector", resData.data);
            navigate("/your-task-list");
            toast.success(resData.message);
          } else {
            toast.error(resData.message);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message);
        });
    }
  };

  const handleCancelDeniedJob = () => {
    fetchCounterOfferDetails();
    setshowdenieJobModel(false);
  };

  useEffect(() => {
    if(!isTourRunning){
      fetchHustler();
    fetchHustlerAppliedTaskDetail();
    fetchallReview();
    fetchTask();
    fetchCounterOfferDetails();
    }
  }, []);

  const saveParticipantDetails = () => {
    const payload = {
      participant_id: hustlerId,
      taskId: taskId,
      profilePic: userDetail?.profilePic,
      participant_name: userDetail?.fname + " " + userDetail?.lname,
      showWelcomeMessage: true,
    };
    dispatch(setParticipantDetails(payload));
    // navigate("/messages");
    navigate("/messages", { state: { id: true } });  // Passing state

  };



  
  return (
    <>
     {isTourRunning?
    <InterstedHustlerStart></InterstedHustlerStart>: 

    <>

      
      <div className="h-100px"></div>

      <section className="container--profile--view intersted--hustler--profile">
        <div
          className="profile--view--header"
          style={{
            alignItems: TaskDetail?.cancellation ? "start" : "",
          }}
        >
          <div className="profile--info">
            <div className="thumbnail--profile">
              <div className="thumbnail--profile--image">
                <img
                  src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetail.profilePic}`}
                />
              </div>
              <div className="thumbnail--info">
                <h2>
                  {userDetail?.fname} {userDetail?.lname}
                </h2>
                <p className="location--hustler">
                  {userDetail?.hustlerDetails?.province},{" "}
                  {userDetail?.hustlerDetails?.location}
                </p>
                {/* <h6>{userDetail?.hustlerDetails?.serviceName}</h6> */}
                <div>
                  <p className="search--category mb-1">
                    <img src="/assets/images/hustler--taskdone.png" />{" "}
                    {userDetail?.hustlerDetails?.completedTask} Completed Jobs
                  </p>
                  <p className="starting--price">
                    <img src="/assets/images/hustler-star.png" />{" "}
                    {handleDecimalNumber(userDetail?.hustlerDetails?.ratings)}
                  </p>
                </div>
              </div>
            </div>
          </div>
          {!TaskDetail?.cancellation && (
            <div className={`profile--book--save`}>

              {userDetail?.status == "inactive" ? <h4 className="playFairDisplay">{"The Hustler is not active."}</h4> :
                <>
                  {/* <button
                onClick={handleDeniedJob}
                className="custom--btn ligghtbtn-hover transparent--btn minbutton"
              >
                <span>
                  <BsChatRightText />  Decline Offer 
                </span>
              </button> */}



                  <button
                    onClick={saveParticipantDetails}
                    className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                  >
                    <span>
                      <BsChatRightText /> Send Message
                    </span>
                  </button>

                  {allCounterOfferdetails.length > 0 ? (
                    <button
                      onClick={() => handleViewCounterOffer()}
                      className="custom--btn darkbtn-hover minbutton"
                    >
                      <span>
                        <BsChatRightText /> View Counter Offer
                      </span>
                    </button>
                  ) : (
                    <>
                      <button
                        onClick={handleSubmit}
                        className="custom--btn ligghtbtn-hover transparent--btn minbutton"
                      >
                        <span>
                          <BsChatRightText /> Accept Offer
                        </span>
                      </button>
                      <button
                        onClick={() => handleCounterOffer()}
                        // className="custom--btn darkbtn-hover minbutton"
                        className="custom--btn ligghtbtn-hover transparent--btn minbutton"

                      >
                        <span>
                          <BsChatRightText /> Counter Offer
                        </span>
                      </button>
                    </>
                  )}

                </>
              }
            </div>
          )}

          {TaskDetail?.cancellation && (
            <>
              <button
                type="button"
                className="custom--btn darkbtn-hover mini-btn"
                onClick={() => setCounterOfferCancelledModal(true)}
              >
                <span>View Reason</span>
              </button>

              {counterOfferCancelledModal && (
                <>
                  <div className="modal-reason--cancel">
                    <div className="modal-header flex-column">
                      <h4 className="modal-title w-100 text-left">
                        Rejected Job Reason
                      </h4>
                      <div
                        className="close--decline"
                        onClick={() => setCounterOfferCancelledModal(false)}
                      >
                        <IoClose />
                      </div>
                    </div>
                    <div className="modal-body pt-4 pb-4">
                      <p>{TaskDetail?.cancellation?.comment}</p>
                    </div>
                  </div>
                  <div className="overlay--modal--cancelld"></div>
                </>
              )}
            </>
          )}
        </div>

        <div className="profile--sarvice--category">

          {userDetail?.hustlerDetails?.services.length > 0 && userDetail?.hustlerDetails?.services.map((service, index, array) => (
            <span key={service._id}>{service.serviceName}{index !== array.length - 1 && ', '}</span>
          ))}
        </div>

        <ul className="category--child">
          {userDetail?.hustlerDetails?.services.map(service => (
            <>
              {service.category.map(category => (
                <li key={category._id} style={{ textTransform: 'capitalize' }}>
                  {category.label}
                </li>
              ))}
            </>
          ))}
        </ul>

        <p>{userDetail?.description}</p>

        <div className="profile--content--area" style={{ marginTop: '10px' }}>
          <div className="profile--content--leftside w-100">
            <div
              className="profile--price"
              style={{
                border: allCounterOfferdetails ? "none" : "",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <>
                <div className="container--counter--table">
                  <div className="director--counter--table">
                    <div className="first-initial">
                      {/* <p>
                        <FaDollarSign />
                        Job Budget: $
                        {BudgetFormat(`${TaskDetail?.task?.budget}`)}
                      </p> */}

                      {TaskDetail?.task?.budget === 0 ?
                        <p>
                          <FaDollarSign /> Job Budget: Open to Offer
                        </p>
                        :
                        <p>
                          <FaDollarSign /> Job Budget:$ {BudgetFormat(`${TaskDetail?.task?.budget}`)}
                        </p>
                      }

                      <p>
                        <AiOutlineCalendar
                          style={{ background: "black", color: "white" }}
                        />
                        Job Date:{" "}
                        {/* {setReportFormatDate(TaskDetail?.task?.dueDate)} */}
                         {TaskDetail?.task?.dueDate ?
                             (
                              <> {setReportFormatDate(TaskDetail?.task?.dueDate)} to  {setReportFormatDate(TaskDetail?.task?.endDate)}</>)
                             :(setReportFormatDate(TaskDetail?.task?.dueDate))
                             }
                      </p>
                    </div>
                    {allCounterOfferdetails &&
                      allCounterOfferdetails.length > 0 && (
                        <>
                          <div className="head-table" style={{ marginTop: '30px' }}>
                            <span>Your Bid</span>
                            <span>Date</span>
                          </div>
                          {allCounterOfferdetails?.map(
                            (item) =>
                              item.userType === "DIRECTOR" && (
                                <div
                                  key={item.id}
                                  className="counter--table--list"
                                >
                                  <span>
                                    ${BudgetFormat(`${item.offerPrice}`)}
                                  </span>
                                  <span>
                                    {setReportFormatDate(item?.proposalDate)}
                                  </span>
                                </div>
                              )
                          )}
                        </>
                      )}
                  </div>

                  <div className="hustler--counter--table" >
                    <div className="first-initial">
                      <p>
                        <FaDollarSign />
                        <span><span style={{ textTransform: 'capitalize' }}>{userDetail?.fname}</span>'s</span> Updated Bid: $
                        {BudgetFormat(`${TaskDetail?.offer}`)}
                      </p>
                      <p>
                        <AiOutlineCalendar
                          style={{ background: "black", color: "white" }}
                        />
                        <span><span style={{ textTransform: 'capitalize' }}>{userDetail?.fname}</span>'s</span> Updated Job Date:{" "}
                        {setReportFormatDate(TaskDetail?.proposalDate)}

                      </p>
                    </div>
                    {allCounterOfferdetails &&
                      allCounterOfferdetails.length > 0 && (
                        <>
                          <div className="head-table" style={{ marginTop: '30px' }}>
                            <span><span style={{ textTransform: 'capitalize' }}>{userDetail?.fname}'s</span> updated Bid</span>
                            <span><span style={{ textTransform: 'capitalize' }}>{userDetail?.fname}'s</span> updated Job Date</span>
                          </div>
                          {allCounterOfferdetails?.map(
                            (item) =>
                              item.userType === "HUSTLER" ? (
                                <div
                                  key={item.id}
                                  className="counter--table--list"
                                >
                                  <span>
                                    ${BudgetFormat(`${item.offerPrice}`)}
                                  </span>
                                  <span>
                                    {setReportFormatDate(item?.proposalDate)}
                                  </span>
                                </div>
                              ) : (
                                <>
                                  {/* {
                                  allCounterOfferdetails&&allCounterOfferdetails.map((item)=>item.userType == "HUSTLER" &&  <div className="counter--table--list">
                                  <span>No bid from {userDetail?.fname}'s</span>
                                </div>)
                                } */}

                                  {
                                    checkHustlerCounterOffer(allCounterOfferdetails) && <div className="counter--table--list">
                                      <span>No bid from {userDetail?.fname}'s</span>
                                    </div>
                                  }

                                </>
                              )
                          )}
                        </>
                      )}
                  </div>
                </div>
              </>
            </div>

            <div className="profile--description">
              {/* <div className="category--profile">
                <ul>
                  {userDetail?.hustlerDetails?.services &&
                    userDetail?.hustlerDetails?.services.length > 0 && (
                      <>
                        {userDetail?.hustlerDetails?.services[0].category
                          .slice(0, 2)
                          .map((category) => (
                            <li key={category._id}>{category.label}</li>
                          ))}
                      </>
                    )}
                </ul>
              </div> */}

              {/* <p>{userDetail?.description}</p> */}
            </div>

            <div className="message--description">
              {/* <h2>{userDetail?.fname}'s Comment</h2> */}
              <h2 style={{ fontFamily: "Inter, sans-serif" }}>{userDetail?.fname}'s Comment</h2>

              <p>{TaskDetail?.description}</p>
            </div>

            {showBookHustler && (
              <BookHustlerPopup
                handleSubmit={handleSubmit}
                handleCancelPopup={handleCancelPopup}
              />
            )}
            {showdirectorCounterOffermodel && (
              <DirectorCounterOfferModel
                hustlerId={hustlerId}
                CounterOffer={CounterOffer}
                allCounterOfferdetails={allCounterOfferdetails}
                handleDeniedJob={handleDeniedJob}
                setallCounterOfferdetails={setallCounterOfferdetails}
                setshowdirectorCounterOffermodel={
                  setshowdirectorCounterOffermodel
                }
                fetchCounterOfferDetails={fetchCounterOfferDetails}
                //   handleViewCounterOfferDetails={handleViewCounterOfferDetails}
                incomingJobtaskId={incomingJobtaskId}
                singleTask={singleTask}
                handleAcceptcounterOffer={handleAcceptcounterOffer}
              />
            )}

            {showPaymentForm && (
              <div className="payment-form">
                <Elements stripe={stripePromise}>
                  <PaymentForm
                    finaltaskDetails={finaltaskDetails}
                    setshowPaymentForm={setshowPaymentForm}
                    paymentIntentId={paymentIntentId}
                    onSuccess={() => {
                      bookHustler();
                    }}
                  />
                </Elements>
              </div>
            )}

            {/* {BeforeShowPaymentForm && (
        <div className="payment-form">
            <BeforePaymentForm
              finaltaskDetails={finaltaskDetails}
              setBeforeShowPaymentForm={setBeforeShowPaymentForm}
              handleSubmit={handleSubmit}
            />
        </div>
      )} */}


            {firstTimeCounterOffer && (
              <DirectorFirstCounterOfferModel
                incomingJobtaskId={incomingJobtaskId}
                CounterOffer={CounterOffer}
                TaskDetail={TaskDetail}
                setfirstTimeCounterOffer={setfirstTimeCounterOffer}
              />
            )}
            {showdenieJobModel && (
              <ConfirmDeniedJobModel
                handleCancelDeniedJob={handleCancelDeniedJob}
                handleDeniedJob={handleDeniedJob}
                setshowdenieJobModel={setshowdenieJobModel}
              />
            )}

            <ProfileReviews userDetail={userDetail} comments={comments} />
          </div>
        </div>
      </section>

    </>
          }
          </>
  );
};

export default IsLoadingHOC(InterstedHustler);

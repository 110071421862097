import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../Redux/Reducers/authSlice";

const HomePage = () => {
  const userType = useSelector((state) => state.auth?.user?.userType);
  const navigate = useNavigate();
  const dispatch=useDispatch()
  useEffect(() => {
    if(userType=="ADMIN"){
      dispatch(logout())
      navigate("/")
    }
    else if (userType) {
      const destination =
      userType === "DIRECTOR"
        ? "/creatrhome"
        : userType === "HUSTLER"
        ? "/hustlerhome"
        : "/home";
        
        navigate(destination);

    //  navigate("/home")
    } else {
   navigate("/")
    }
  }, []);

  return <div></div>;
};

export default HomePage;

import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { BudgetFormat, displayBudget, showDecimalNumber } from "../../utils/helper";
import { Link } from "react-router-dom";

const PaymentForm = ({ paymentIntentId, setshowPaymentForm, finaltaskDetails, onSuccess, handleCancelPopup , fetchProgressTask}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const serviceFeePercentage = 0.18;
  const serviceFee = (finaltaskDetails?.budget * serviceFeePercentage).toFixed(2);
  const totalPayment = (finaltaskDetails?.budget * (1 + serviceFeePercentage)).toFixed(2);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    const result = await stripe.confirmCardPayment(paymentIntentId, {
      payment_method: {
        card: elements.getElement(CardElement),
      },
    });

    if (result.error) {
      toast.error(result.error.message);
      setLoading(false);
    } else {
      if (result.paymentIntent.status === "succeeded") {
        toast.success("Payment successful!");
        onSuccess();
        if (fetchProgressTask) {
          fetchProgressTask(1);  
        }
  

      }
    }
  };

  const handleClose = () => {
    setshowPaymentForm(false)
    if (handleCancelPopup) {
      handleCancelPopup()
    }
  }

  return (
    <>
      <div>
        <div
          className={`deletemodal modal ${setshowPaymentForm ? "show" : ""}`}
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-lg" role="document">
            <div className="modal-content">
              <div className="modal-header flex-column">
                <h4 className="modal-title w-100 text-center">
                  Please Confirm Your Payment
                </h4>
                <button
                  type="button"
                  className="close"
                  onClick={handleClose}
                  // onClick={()=>handleCancelPopup(false)}
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body pt-5 pb-5 confirmation--modal">
                <form onSubmit={handleSubmit}>
                  <div className="request--task--work" style={{ marginTop: 0, marginBottom: '10px' }}>
                    <p className="mb-0">
                      {/* <b>Task Budget:</b> ${BudgetFormat(`${finaltaskDetails?.budget}`)} */}
                      
                      <b>Creatr Budget:</b> ${BudgetFormat(`${finaltaskDetails?.budget}`)}

                    </p>
                    <p className="mb-0">
                      <b>Service Fee (18%):</b> ${BudgetFormat(`${serviceFee}`)}
                    </p>
                    <p className="mb-0">
                      <b>Total Payment:</b> ${BudgetFormat(`${totalPayment}`)}
                    </p>
                  </div>
                  <div className="payment-card">
                    <CardElement />
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  to='/payment-failed'

                  className="button--link custom--btn ligghtbtn-hover transparent--btn"
                  onClick={handleClose}
                  // onClick={()=>handleCancelPopup(false)}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <span>Cancel</span>
                </button>
                <button
                  type="button"
                  className="btn btn-dark darkbtn-hover"
                  onClick={handleSubmit}
                  disabled={!stripe}
                >
                  <span>{loading ? "Processing..." : "Pay Now"}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`modal-backdrop ${setshowPaymentForm ? "show" : ""}`}
        ></div>
      </div>
    </>
  );
};

export default PaymentForm;

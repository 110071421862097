import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate,Link } from "react-router-dom";
import { withoutAuthAxios } from "../services/config.js";
import IsLoadingHOC from "../common/IsLoadingHOC.js";
import {
  saveServices,
  saveTaskOptions,
  CombineTaskOptions,
} from "../Redux/Reducers/appSlice.js";
import {
  RemovingDuplicatesServicesandCategories,
  toCamelCase,
} from "../utils/helper.js";
import CountdownTimer from "./CountdownTimer.js";
import "./style.css";
import MailchimpSignupForm from "./MailchimpSignupForm.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "../../public/assets/scss/pages/_landing22.scss"

// import "./landingBefore.css"

const LandingPageBefore = (props) => {
  const userType = useSelector((state) => state.auth?.user?.userType);

  const openNotification = () => {
    toast.success("Form submitted successfully.")
  
  };
  const errorNotification = () => {
    toast.error("Please select at least one checkbox.");
  };
  const errorOneNotification = () => {
    toast.error("Please select only one checkbox.");
  };

  

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const navigate = useNavigate();
  const token = useSelector((state) => state?.auth?.accessToken);
  const dispatch = useDispatch();
  // const { setLoading } = props;

  const getTaskListData = async () => {
    // setLoading(true);
    await withoutAuthAxios()
      .get(`/services/get-all-services`)
      .then((response) => {
        const resData = response?.data;
        // setLoading(false);
        if (resData?.status == 1) {
          // dispatch(saveTaskOptions(resData?.data));
          const services = [];
          resData?.data.map((item) => {
            services.push({
              label: toCamelCase(item?.serviceName),
              value: toCamelCase(item?.serviceName),
            });
          });

          const category = [];

          resData?.data?.map((item) => {
            item?.category?.map((cat) => {
              category?.push(cat);
            });
          });

          dispatch(CombineTaskOptions(resData));
          dispatch(
            saveServices(RemovingDuplicatesServicesandCategories(services))
          );
          dispatch(
            saveTaskOptions(RemovingDuplicatesServicesandCategories(category))
          );

        } else {
          toast.error(resData?.message?.message);
        }
      })
      .catch((error) => {
        // setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
    getTaskListData();
  }, []);

  useEffect(() => {
    if (token) {
      // navigate("/home");
      const destination =
      userType === "DIRECTOR"
        ? "/creatrhome"
        : userType === "HUSTLER"
        ? "/hustlerhome"
        : "/home";
        
        navigate(destination);

      
    }
  }, []);

  const [landingPage, setLandingPage] = useState(true);
  const [animationWidth, setAnimationWidth] = useState(false);

  const handleWidthAnimation = (item) => {
    setAnimationWidth(true);

    setTimeout(() => {
      setAnimationWidth(false);
      setLandingPage(false);
    }, 1000);

    setTimeout(() => {
      navigate(item);
    }, 500);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  // <!-- Google tag (gtag.js) -->
  // <script async src="https://www.googletagmanager.com/gtag/js?id=G-51W4PN600H"></script>
  // <script>
  //   window.dataLayer = window.dataLayer || [];
  //   function gtag(){dataLayer.push(arguments);}
  //   gtag('js', new Date());
  
  //   gtag('config', 'G-51W4PN600H');
  // </script>
  // add this code in landing page in head tag Shubham 
  useEffect(() => {
    // Adding the Google Analytics script dynamically
    const script1 = document.createElement('script');
    script1.src = 'https://www.googletagmanager.com/gtag/js?id=G-51W4PN600H';
    script1.async = true;
    document.head.appendChild(script1);

    // Adding the Google Analytics config script dynamically
    const script2 = document.createElement('script');
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){
        console.log("Google Analytics gtag called with arguments: ", arguments); // Log gtag call
        dataLayer.push(arguments);
      }
      gtag('js', new Date());
      gtag('config', 'G-51W4PN600H');
    `;
    document.head.appendChild(script2);
  }, []); // Empty dependency array ensures this runs only once when the component mounts



  return (
    <>
 <ToastContainer 
          className="toaster-container"
          // position="top-right"
          style={{ zIndex: 100000 }}
          autoClose={3000} // Auto-close after 5 seconds (customizable)
          hideProgressBar={true} // Hide progress bar
          newestOnTop={true} // Show newest toasts on top
          closeOnClick={true} // Allow closing on click
          rtl={false} // Not using RTL layout
          pauseOnFocusLoss={false} // Don't pause when focus is lost
          draggable={true} // Allow dragging
          pauseOnHover={true} // Pause on hover
          />
      {landingPage && (
        <div className="container--landing">
          <style>
{`.container--landing {
    height: 100vh;
    overflow: hidden;
    position: absolute;
    width: 100%;
    z-index: 99999;
    top: 0;

    @media(max-width:991px) {
        overflow: auto;
    }

    .logo--sticy {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        width: 100%;
        z-index: 999;
        transition: 1s;
        display: flex;
        flex-direction: column;
        align-items: center;
        


        img {
            width: 100%;
            max-width: clamp(210px, 18vw, 295px);
        }

        .headline {
            font-size: clamp(21px, 3vw, 45px);
            margin-top: 10px;
            margin-right: 10px;

            span {
                color: var(--white);
                margin-left: 10px;

                @media(max-width:991px) {
                    margin-left: 0;
                    color: var(--darkblack);
                }
            }
        }
    }

    .logo--sticy.animated-up {
        top: -100%;
    }


    .row {
        height: 100%;


        @media(max-width:991px) {
            display: block;
            position: absolute;
            overflow: auto;
            top:0px;
            height:calc(100vh - 60px);
            width:100%;
            margin:0px;
            margin-top: 60px;
        }

        .director--div,
        .hustler--div {
            display: flex;
            align-items: flex-start;   
                       
            @media(max-width:991px){
                padding: 20px;
                height:auto;
            }
            @media(max-width:767px){
                height:auto;
            }
            @media(max-width:991px){
                padding: 10px;
            }

            .content--width {
                max-width: 540px;
                margin: 0 10%;
                padding: 20px;

                @media(max-width:1650px){
                    max-width: 460px;
                    margin: 0 10%;
                }
                @media(max-width:1369px){
                    max-width: 400px;
                    margin: 0 5%;
                }                
                @media(max-width:991px){
                    max-width: 80%;
                    padding: 0;
                }
                @media(max-width:600px){
                    max-width: 100%;
                    width:100%;
                    padding: 0;
                    margin: 0;
                }

                h2 {
                    margin-bottom: clamp(10px, 2vw, 20px);
                    font-size: clamp(20px, 2.4vw, 40px);
                }

                img {
                    width: clamp(50px, 5vw, 80px);
                    aspect-ratio: 1;
                    margin-bottom: clamp(15px, 2vw, 10px);

                    @media(max-width:991px){
                        margin-bottom: 10px;
                        width: 45px;
                    }
                    @media(max-width:767px){
                        margin-bottom: 6px;
                        width: clamp(35px, 6vw, 105px);
                    }
                }

                p {
                    text-align: justify;
                    margin-bottom: 10px;
                    word-break: keep-all;
                }

                hr {
                    width: 50px;
                    opacity: 1;
                    border: 1px solid var(--darkblack);
                    margin: 0px;
                }

                button{
                    @media(max-width:767px){
                        margin-top: 10px !important;
                        max-width: 140px;
                    }
                }
            }
        }        

        .hustler--div {
            background: var(--darkblack);            
            justify-content: flex-end;
            hr {
                border: 1px solid var(--white) !important;
            }

            h2 {
                color: var(--white);
            }

            p {
                color: var(--white);
            }
        }

        .director--div.animated-left {
            transform: translateX(-100%);

            @media(max-width:991px) {
                transform: translateX(0%) translateY(-100%);
            }
        }

        .hustler--div.animated-right {
            transform: translateX(100%);

            @media(max-width:991px) {
                transform: translateX(0%) translateY(100%);
                height: 50vh;
            }
        }

        .director--div,
        .hustler--div {
            transition: 1s;
        }

        .director--div {
            background: var(--white);
            justify-content: flex-start;
        }


    }
}
@media(max-width:991px) {   
    .container--landing .row .director--div, .container--landing .row .hustler--div {
        justify-content: center;
    }
    .container--landing .row .director--div .content--width {
        margin-bottom:10%;
    }
    .container--landing .row .hustler--div .content--width {
        margin-top:10%;
        padding-bottom: 390px;
    }
    .mailChimp1 {
        position: absolute;
        bottom: 80px;
    }
    .container--landing .row .director--div {
        margin-top: 5%;
    }
}
@media(max-width:600px) { 
    .container--landing .row .director--div .content--width {
        margin-bottom:15%;
    }
    .container--landing .row .hustler--div .content--width {
        margin-top:15%;
    }
    .container--landing .row .director--div {
        margin-top: 2%;
    }
}`}

          </style>
          <style>
            {`

             .More_hustl {
             position: absolute;
             left: 10px;
             bottom: -5px;
             }
              @media only screen and (max-width: 821px) {
                .More_hustl {
                    position: absolute; / Ensure it's absolute /

                    top: -13px;
                    left: auto; / Override the left positioning /
                    right: 18px;
                    // left: 10px; / Override the left positioning /
                    // bottom: auto;                 }
                              }
            `}
          </style>


          <div className={`logo--sticy ${animationWidth ? "animated-up" : ""}`}>
            <img
              src="./assets/images/sticky-logo.png"
              alt="logo"
              {...(isSmallScreen
                ? {}
                : { "data-aos": "fade-down", "data-aos-duration": "1000" })}
            />
          </div>

          <div className="row" style={{ position: "relative" }}>
            <CountdownTimer></CountdownTimer>
            {/* {props.testingDate} */}
            <div className="launching-date big-screen">
              <h2>
                Launching <span>October 15</span>
              </h2>
            </div>


            <div
              style={{ position: "relative" }}
              className={`lightBgContent col-12 col-lg-6 director--div ${
                animationWidth ? "animated-left" : ""
              }`}
            >
              <div
                className="content--width pt-0"
                {...(isSmallScreen
                  ? {}
                  : { "data-aos": "fade-up", "data-aos-duration": "1000" })}
              >
                <img
                  // src="./assets/images/director-icon.png"
                  src="./assets/images/creater.png"
                  alt="director"
                />
                <h2> Creatr</h2>
                <div className="myContent">
                  <h5>Reclaim your time.</h5>
                  <p>
                    Offload your to-dos to a qualified Hustlr-quick, easy and
                    hassle-free. Post a job, find the right person, and get back
                    to what matters most.
                  </p>
                </div>
              </div>
              <h2 className="bottomFixText big-screen">Less Hassle,</h2>
              <div className="launching-date small-screen">
                <h2>
                  Launching <span>October 15</span>
                </h2>
              </div>
              <div className="bottomFixText small-screen"><h2>Less Hassle, <span>More Hustl</span></h2></div> 

              <div className="social-section">
                <ul className='social-icons big-screen' >
                  <li><Link to="https://www.facebook.com/sidehustl.ca" target='_blank' aria-label='Facebook'><img src='/assets/images/facebook-b.png' alt='Facebook' /></Link></li>
                  <li><Link to="https://www.instagram.com/sidehustl.ca/" target='_blank' aria-label='Instagram'><img src='/assets/images/insta-b.png' alt='Instagram' /></Link></li>                        
              </ul>
              </div> 

            </div>

            <div
              style={{ position: "relative" }}
              className={`darkBgContent col-12 col-lg-6 hustler--div ${
                animationWidth ? "animated-right" : ""
              }`}
            >
              <div
                className="content--width pt-0"
                {...(isSmallScreen
                  ? {}
                  : { "data-aos": "fade-up", "data-aos-duration": "1000" })}
              >
                <img
                  // src="./assets/images/hustler-icon.png"
                  src="./assets/images/onlinejob.png"
                  alt="hustler"
                />
                <h2> Hustlr</h2>
                <div className="myContent">
                  <h5>Earn on your terms.</h5>
                  <p>
                    Work the jobs you want, when you want, and get paid
                    directly. You have the skill, we'll do the rest. Join the
                    community of Hustlrs who are turning side gigs into a
                    flexible lifestyle.
                  </p>
                </div>
              </div>
              <h2 className="bottomFixText big-screen">More Hustl</h2>
              <div className="mailChimp1">
                <MailchimpSignupForm openNotification={openNotification} errorNotification={errorNotification} errorOneNotification={errorOneNotification}/>
              </div>
              <div className="social-section">
                <ul className='social-icons small-screen' >
                  <li><Link to="https://www.facebook.com/sidehustl.ca" target='_blank' aria-label='Facebook'><img src='/assets/images/facebook.png' alt='Facebook' /></Link></li>
                  <li><Link to="https://www.instagram.com/sidehustl.ca/" target='_blank' aria-label='Instagram'><img src='/assets/images/insta.png' alt='Instagram' /></Link></li>                        
              </ul>
              <p>Copyright &copy; 2024 SideHustl</p>
              </div> 

            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LandingPageBefore;

import React, { useEffect, useState } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { FaCheck, FaRegPaperPlane } from "react-icons/fa";
import { useSelector } from "react-redux";
import { setReportFormatDate } from "../../assets/Helper";
import { TbMoneybag } from "react-icons/tb";
import { AiOutlineCalendar } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import {
  BudgetFormat,
  dateFormat,
  displayBudget,
  handleDecimalNumber,
} from "../../utils/helper";
import TaskMedia from "../../common/TaskMedia";
import { useDispatch } from "react-redux";
import { setParticipantDetails } from "../../Redux/Reducers/chatSlice";
import { IoIosArrowRoundBack, IoIosCloseCircle } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";
import { BiTimeFive } from "react-icons/bi";
import { GoArrowRight } from "react-icons/go";
import { BsFillChatLeftTextFill } from "react-icons/bs";
import moment from "moment";
import { FaEye } from 'react-icons/fa'; // Import Font Awesome icon
import {
  MdPublic ,
  MdEmojiPeople,
  MdOutlineRemoveRedEye,
  MdReviews,
} from "react-icons/md";

// import AddReviews from "../../common/AddReviews";
import AddReviews from "../../common/AddReviews";
import { useSocket } from "../../contexts/SocketContext";




const TaskpropsalStart = (props) => {
  const param = useParams();
  const socket = useSocket();

  const { setLoading } = props;
  const { id } = param;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [showReviewModel, setshowReviewModel] = useState(false);
  const [showReviewModelH, setshowReviewModelH] = useState(false);
  const [taskReviewDetails, settaskReviewDetails] = useState({});

  const [allId, setallId] = useState({
    hustlerId: "",
    taskId: "",
    intervalId: "",
    subscriptionId: "",
    reason: "",
  });


  const goBack = () => {
    window.history.back();

    
//     if status:"open"
// /your-task-list
// if status: "assigned"

//      navigate("/your-task-list", { state: { id: "in-progress" } });

// if status: "completed"
//      navigate("/your-task-list", { state: { id: "completed-task" } });


  };
  const [isVisible, setIsVisible] = useState(true); // State to toggle visibility

  // Function to toggle visibility
  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };


  const location = useLocation();
  const statusId = location?.state?.statusId;
  const prevBtn = location?.state?.id;


  const [appliedHustler, setappliedHustler] = useState([]);

  const [Taskdetails, setTaskdetails] = useState([]);



  



  const [review,setReview]=useState()








 

  const handleAddOpenModal = (item) => {
    setallId((prev) => ({
      ...prev,
      hustlerId: item?.hustlerId._id,
      taskId: item?._id,
    }));

    setshowReviewModel(true);
  };

  const handleEditReview = async (item) => {
    const payload = {
      rating: item.rating,
      behavior: item.behavior,
      availability: item.availability,
      skills: item.skills,
      communication: item.communication,
      comment: item.comment,
    };
    const taskId = item.taskId;
    setLoading(true);
    await authAxios()
      .put(`/review/update-review/${taskId}`, payload)
      .then((response) => {
        setLoading(false);
        const resData = response.data;
        if (resData.status == 1) {
          toast.success(resData?.message);
          // fetchCompletedtask(1);
          settaskReviewDetails([]);

          setshowReviewModel(false);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };
  const handleSubmitReview = async (item) => {
    // alert("hi")
    const payload = {
      reviewReceiver: allId.hustlerId,
      rating: item.rating,
      behavior: item.behavior,
      availability: item.availability,
      skills: item.skills,
      communication: item.communication,
      comment: item.comment,
      taskId: allId.taskId,
    };
    setLoading(true);
    await authAxios()
      .post("/review/create-review", payload)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          socket.emit("directorReviewed", resData.data.review);
          setLoading(false);
          toast.success(resData?.message);
          // fetchCompletedtask(1);

          setshowReviewModel(false);
        } else {
          setLoading(false);
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error?.response?.data?.message);
      });
  };


  const handleViewOpenModal = (item) => {
    // fetchSpecificTaskReview(item?._id);
    setshowReviewModel(true);
  };

  // const fetchSpecificTaskReview = async (id) => {
  //   setLoading(true);
  //   await authAxios()
  //     .get(`/review/specific-task-reviews/${id}`)
  //     .then((response) => {
  //       setLoading(false);
  //       const resData = response.data;
  //       if (resData.status == 1) {
  //         settaskReviewDetails(resData?.data[0]);
  //       } else {
  //         toast.error(resData?.message);
  //       }
  //     })
  //     .catch((error) => {
  //       setLoading(false);
  //       toast.error(error.response?.data?.message);
  //     });
  // };




  return (
    <>
      <div>
        <section
          className="director--search--hustler pt-117 hustler--home--page"
          style={{ minHeight: "400px" }}
        >
          <div className="custom--container m-auto pt-5 pb-5">
            <div className="container--filter">
              <div className="filter--data--search" style={{maxWidth: "100%"}}>
                <div className="task--lists--profile--based">
                  <div className="director--list--task">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        <div
                          className={`list--tasks upper--task--list ${
                            prevBtn && "back--btn-active"
                          }`}
                        >
                          <div className="created--task--lists p-0 bg-white border-0" style={{width:'100%'}}>
                            {/* <button                              
                             onClick={goBack}
                            >
                            <IoIosArrowRoundBack /> Back

                            </button> */}
                            <div
                              className="flex"
                              style={{ minWidth: "150px" }}
                              // onClick={goBack}

                              onClick={() => {

                                if (Taskdetails?.status == "open") {
                                  navigate("/your-task-list");
                                } else if (Taskdetails?.status == "in-progress") {
                                  // navigate("/your-task-list/in-progress");

                                  navigate("/your-task-list", { state: { id: "in-progress" } })
                                } 
                                else if (Taskdetails?.status == "assigned") {
                                  // navigate("/your-task-list/in-progress");
                                  navigate("/your-task-list", { state: { id: "in-progress" } })
                                } 
                                else if (Taskdetails?.status == "completed") {
                                  navigate("/your-task-list", { state: { id: "completed-task" } });
                                }
                                else if (Taskdetails?.status == "close") {
                                  // navigate("/your-task-list", { state: { id: "completed-task" } });
                                  navigate("/your-task-list", { state: { id: "in-closed" } });

                                }
                              }}
                      
                            >
                              <button
                                className="custom--btn mb-3 darkbtn-hover mini-btn"
                                style={{ marginLeft: "auto" }}
                              >
                                <span>
                                  <IoIosArrowRoundBack /> Back 
                                </span>
                              </button>
                            </div>


                            <h1
                              className="text--dark playFairDisplay heading-h1 mb-3"
                              style={{ fontSize: "clamp(35px, 3vw, 50px)", wordBreak:'keep-all' }}
                            >
                              {/* {Taskdetails?.title} */}
                              I need my lawn mowing
                            </h1>
                            <div
                              className="task--items--header"
                              style={{
                                flexDirection: "initial",
                                flexWrap: "wrap",
                              }}
                            >
                              <h3>
                                {/* {Taskdetails?.serviceName} */}
                                appliance help
                                </h3>
                                
                            </div>
                          </div>
                          {prevBtn && prevBtn === "view-task-hire" ? (
                            <div
                              className="flex"
                              style={{ minWidth: "150px" }}
                              onClick={goBack}
                            >
                              <button
                                className="custom--btn mb-3 darkbtn-hover mini-btn"
                                style={{ marginLeft: "auto" }}
                              >
                                <span>
                                  <IoIosArrowRoundBack /> Back
                                </span>
                              </button>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                            {/* //===================================== */}
                   
                    {review?.map((reviewItem, index)=>(        
                      <div id="reviews-profile">
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div></div>
                      <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}} >       
                      <FaEye
                          style={{ cursor: 'pointer', color: 'black' ,fontSize:"28px" }} // Black eye icon
                          onClick={toggleVisibility} // Click to toggle visibility
                        />
                        &nbsp;&nbsp;&nbsp;
                        {/* <h3>Review  hustler for this job</h3> */}
                        <h4> Hustlr Review</h4>
                       </div>

      </div>

      {/* Conditionally render the comments section */}

      {isVisible && (
        <div className="comments--profile--lists">
          <div className="reviews--section">
            {/* <div className="no--comments">
              <h5 style={{ textAlign: 'center', color: '#ada7a7' }}>No Reviews</h5>
            </div> */}

            <div className="user--comment--items">
              <div className="user--comment--header">
                <div className="user--comment--details">
                  <img
                    // src="profile_image_placeholder.jpg"
                    src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${reviewItem?.reviewSender?.profilePic}`}

                    alt=""
                    className="user--profile--img"
                    style={{
                      height: '80px',
                      width: '80px',
                      borderRadius: '10px',
                      objectFit: 'cover',
                    }}
                  />
                  <div className="user--comment--detail">
                    <h3>John Doe</h3>
                    <h3>
                        
                        {`${reviewItem.reviewSender.fname} ${reviewItem.reviewSender.lname}`}</h3>

                    <span className="user--rating--comment">
                      <img src="/assets/images/hustler-star.png" alt="rating" />
                      {reviewItem.rating}

                    </span>
                    <span>
                    {" "}{" "}
                                {setReportFormatDate(reviewItem?.createdAt)}
                    {/* <span>{new Date(reviewItem.createdAt).toLocaleDateString()} */}

                    </span>

                  </div>
                </div>
                <div className="view--task--comment">
                  <span style={{ cursor: 'pointer' }}>
                    <svg width="24" height="24"></svg>
                  </span>
                </div>
              </div>

              <div className="user--comment--description">
                <p>{reviewItem?.comments}</p>
                {/* <span
                  className="read-more-toggle"
                  style={{
                    cursor: 'pointer',
                    color: '#1f1f28',
                    textDecoration: 'underline',
                    fontWeight: '600',
                  }}
                >
                  Read More
                </span> */}
              </div>
            </div>
          </div>
        </div>
      )}
    
    </div>))}


    




                          {/* /====================================================== */}

                         { Taskdetails?.status=="completed"?        <div>

                          <div className="task--items--edit--delete completed-jobs">
                              {Taskdetails?.directorReviewed ? (
                                <button
                                  onClick={() => handleViewOpenModal(Taskdetails)}
                                  className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                >
                                  <span>
                                    <MdOutlineRemoveRedEye /> My Review
                                  </span>
                                </button>
                                
                              ) : (
                                <button
                                  // onClick={() => {handleAddOpenModal(Taskdetails)
                                  //   settaskReviewDetails(0)}}
                                    onClick={() => {
                                      handleAddOpenModal(Taskdetails);
                                      settaskReviewDetails(0);
                                   }}
                                
                                  className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                >
                                  <span>
                                    <MdReviews /> Add Review 
                                  </span>
                                </button>
                              )}
                            </div>                          
                            </div>:""
                            }
                          {/* ============================================== */}




                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    ></ul>

                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        <div className="list--tasks">
                          <div className="created--task--lists bg-white border-0 p-0">
                            <div className="task--items-down--footer task--para pb-2 pt-4">
                              <p>
                                <span className=" task--items--content--icon">
                                  <TbMoneybag />
                                </span>
                                Budget: ${BudgetFormat(`${200}`)}
                              </p>
{true ? (
  <p>
    <span className="task--items--content--icon">
      <AiOutlineCalendar />
    </span>
     {/* Date Range: {`${dateFormat(Taskdetails.dueDate)} to ${dateFormat(Taskdetails.endDate)}`} */}
     Date Range: {`${dateFormat(new Date())} to ${dateFormat(new Date())}`}

  </p>
) :                              (  

  <p>
    <span className="task--items--content--icon">
      <AiOutlineCalendar />
    </span>
    Job Date: {dateFormat(new Date())}  
    
    </p>

)
}


                              <p>
                                <span className="task--items--content--icon">
                                  <GrLocation className="location" />
                                </span>
                                Area:  Kamloops
                                {/* {Taskdetails?.cityName} */}
                              </p>
                            </div>
                          </div>

                          
                          

                            <div
                              className="created--task--lists border-0"
                              style={{
                                padding: "15px",
                                backgroundColor: "#f5f5f5",
                                marginBottom: "0px",
                              }}
                            >
                              <div className="task--items-down--footer task--para pt-0 final--biding">
                                
                                  
                                  
                                  <>
                                   
                                   <p
                                    className={`${
                                      Taskdetails?.updatedDate ? "" : "mb-0"
                                    }`}
                                  >
                                    <span className="task--items--content--icon">
                                    <AiOutlineCalendar />
                                    </span>
                                    <b>Final Date:</b>
                                  <span>{dateFormat(Taskdetails?.latestCounterOffer
                                          ?.finalDate?Taskdetails?.latestCounterOffer
                                          ?.finalDate:Taskdetails?.taskProposal?.proposalDate) }</span>
                                  </p> 


                                  
                                 

                                  <p
                                    className={`${
                                      Taskdetails?.updatedDate ? "" : "mb-0"
                                    }`}
                                  >
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>
                                    <b>Final Bid:</b> $
                                    {BudgetFormat(`${Taskdetails?.finalPrice}`)}
                                  </p>


                                  </>
                                
                                
                              </div>
                            </div>
                          
                        

                          <TaskMedia imagePaths={Taskdetails?.attachments} />

                          <div className="created--task--lists p-0 border-0 bg-white pb-3 mb-0">
                            <div className="task--items-footer">
                              <h3
                                className="playFairDisplay"
                                style={{ fontSize: "22px" }}
                              >
                                Job Description:
                              </h3>
                              {/* <p>{Taskdetails?.description}</p> */}
                              <p>My lawn is 100 meter square</p>

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                   
                      <>
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active"
                            id="upcoming"
                            role="tabpanel"
                            aria-labelledby="upcoming-tab"
                          >
                            <div className="list--tasks">
                              <div className="haading--task--list pt-4">
                                <div className="task--items--header mb-0">
                                  <h3>Available Applicants</h3>
                                  <p>Total: 1</p>
                                </div>
                              </div>
 {/* // ========================================================================== */}
<div className="filter--data--value interested--hustlers--task">
  <div className="search--list--items">
    <div className="header--search--list--items member--task--list">
      <div className="thumb--header--search--list--items">
        <img
        //   src={`public/assets/images/hustler-profile-img/hustler--profile-4.png`}
          src={`/assets/images/hustler-profile-img/hustler--profile-4.png`}
          alt="Profile"
          style={{ width: "65px" }}
        />
      </div>

      <div className="thumb--header--search--list--start">
        <div className="div-1">
          <h3 className="hustler--category" style={{ display: "block" }}>
            <span> HUSTLR </span> PROFILE
          </h3>
          <div className="thumb--header--search--list--middle">
            <p>
              <img src="/assets/images/hustler-star.png" alt="Star" /> 4.3
            </p>
            <p>
              <img
                src="/assets/images/hustler--taskdone.png"
                alt="Tasks Done"
              />{" "}
              14 Completed Tasks
            </p>
          </div>
        </div>
      </div>

      <div className="thumb--header--search--list--middle">
        <p>
          Bid: $80.00
          {/* | Members: 5 */}
        </p>
      </div>

      <div className="thumb--header--search--list--end thumb--header--search--list--proposal" style={{maxWidth:'211px !important'}}>
  <Link
    // to={`/intersted-hustlr-profile/${item.hustlerId}/${id}`}
    className="custom--btn ligghtbtn-hover transparent--btn text-center page-five-element"
    style={{
      fontSize: "clamp(14px,2vw,16px)",
      paddingLeft: "10px",
      paddingRight: "10px",
    }}
  >
    <span>
      <FaRegPaperPlane />{" "}
      View Proposal
    </span>
  </Link>
</div>


    </div>
  </div>
</div>

                               {/* //================================================================================ */}
                            </div>

                          </div>
                        </div>
                      </>
                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {showReviewModel && (
        <AddReviews
          SensitiveWords={null}
          taskReviewDetails={taskReviewDetails}
          handleEditReview={handleEditReview}
          handleSubmitReview={handleSubmitReview}
          setshowReviewModel={setshowReviewModel}
          settaskReviewDetails={settaskReviewDetails}
        />
      )}

    </>
  );
};

export default IsLoadingHOC(TaskpropsalStart);

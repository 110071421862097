import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Link } from '@react-pdf/renderer';
import {  dateFormatWithMilli, payloadBudget } from '../../utils/helper';

const styles = StyleSheet.create({
  page: {
    backgroundColor: '#F7FAFC',
    paddingTop: 30,
    paddingLeft: 10,
    paddingRight: 20,
    lineHeight: 1.5,
  },
  header: {
    backgroundColor: "#000",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    padding: 8,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerImage: {
    width: 30,
  },
  title: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 10,
    fontSize: 16,
    fontWeight: 'bold',
    color: '#000',
  },
  section: {
    paddingHorizontal: 20,
    paddingVertical: 10,
  },
  sectionTitle: {
    fontSize: 10,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#000',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  textBold: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#000',
  },
  text: {
    fontSize: 10,
    color: '#000',
  },
  summaryContainer: {
    backgroundColor: '#f3f4f6',
    borderRadius: 10,
    padding: 10,
    marginTop: 10,
  },
  summaryItem: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 5,
  },
  summaryTotal: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontWeight: 'bold',
    marginTop: 10,
  },
  contactInfo: {
    marginTop: 20,
    textAlign: 'center',
    color: '#000',
  },
});

const PaymentInvoicePdf = ({ paymentDetails,FinalBid }) => {

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <Image
            src="https://stripe-images.s3.amazonaws.com/emails/acct_1OhFFXF3HwezfEYa/5/twelve_degree_icon@2x.png"
            style={styles.headerImage}
          />
        </View>
        <Text style={styles.title}>Receipt from SIDEHUSTL</Text>
        <View style={styles.section}>
          <View style={styles.row}>
            <Text style={styles.textBold}>Amount paid:</Text>
            <Text style={styles.text}>${(paymentDetails?.payment?.amount / 100)?.toFixed(2)}</Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.textBold}>Date:</Text>
            <Text style={styles.text}>
            {dateFormatWithMilli(paymentDetails?.payment?.created)}
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.textBold}>Payment method:</Text>
            <Text style={styles.text}>
            xxxx-xxxx-xxxx-{paymentDetails.payment?.payment_method_details?.card?.last4}
            </Text>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={styles.sectionTitle}>Summary</Text>
          <View style={styles.summaryContainer}>
            <View style={styles.summaryItem}>
              <Text style={styles.text}>Payment to SIDEHUSTL</Text>
              <Text style={styles.text}>${FinalBid}</Text>
            </View>
            <View style={styles.summaryTotal}>
              <Text style={styles.textBold}>Service fee</Text>
              {/* <Text style={styles.text}>${payloadBudget(`${ (0.18) * FinalBid}`)}</Text> */}
              <Text style={styles.text}>${payloadBudget(`${(Number(paymentDetails?.task?.stripePaymentAmount) - Number(FinalBid)).toFixed(2)}`)}</Text>              
            </View>
            <View style={styles.summaryTotal}>
              <Text style={styles.textBold}>Amount charged</Text>
              <Text style={styles.text}>${(paymentDetails?.payment?.amount_captured / 100)?.toFixed(2)}</Text>
            </View>
          </View>
        </View>
        <View style={styles.section}>
          <Text style={{ fontSize: '10px', color: '#374151' }}>
            If you have any questions, contact us at{' '}
            <Link href="mailto:admin@sidehustl.ca" style={styles.contactInfo}>
              admin@sidehustl.ca
            </Link>
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default PaymentInvoicePdf;
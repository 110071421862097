import React, { useEffect, useState } from "react";
import { IoIosCloseCircleOutline, IoIosSearch } from "react-icons/io";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { AiOutlineCalendar, AiOutlineHeart } from "react-icons/ai";
import { TbMoneybag } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";
import FilterRightSide from "./FilterRightSide";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { setReportFormatDate } from "../../assets/Helper";
import { MdReviews } from "react-icons/md";
import HustlerAddReview from "../../common/HustlerAddReview";
import AddReviews from "../../common/AddReviews";
import {
    BsChatRightText,
    BsChevronDown,
    BsListCheck,
    BsListTask,
    BsSend,
  } from "react-icons/bs";
  

import { FaRegCircleCheck } from "react-icons/fa6";
import AcceptIncomingJobModel from "./AcceptIncomingJobModel";
import CancelIncomingJobModel from "./CancelIncomingJobModel";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { PiEye } from "react-icons/pi";
import HustlerCompletedJobModal from "../../common/Modal/HustlerCompletedJobModal";
import { BiSolidCaretLeftSquare, BiTimeFive } from "react-icons/bi";
import { BudgetFormat, handleDecimalNumber } from "../../utils/helper";
import { useSocket } from "../../contexts/SocketContext";
import { useLocation } from "react-router-dom";
import ShowCancelDisableModal from "../../common/Modal/ShowCancelDisableModal";

import { GoArrowRight } from "react-icons/go";
import { LuPartyPopper } from "react-icons/lu";
import CounterOfferJobModel from "../../common/Modal/CounterOfferJobModel";
import DirectorcancelJobReason from "./DirectorcancelJobReason";
import HustlerDeclinedJobCompletedModal from "./HustlerDeclinedJobCompletedModal";
import JobFrequently from "../../common/JobFrequently";
import Pagination from "../../common/Pagination";
import HustlerJobsCompleted from "./HustlerJobsCompleted";


function HustlerHomeNewJob() {
  return (
<div key={1} className="created--task--lists process--task--list">
  <div className="new-jobs new--jobs-h" style={{ zIndex: "auto" }}>
    <span>
      <BsListTask /> New Job
    </span>
  </div>
  <div className="task--work">
    <div className="task--items--header" style={{ marginBottom: "0px" }}>
      <div className="task--add--info">
        <div className="user--task--info">
          <div className="task--info--img">
            <Link to="/user-info/123">
              <img 
                              src="../assets/images/hustler-profile-img/hustler--profile-1.png"

            //   src="../assets/images/default-profile-pic.png" 
              alt="User" />

            </Link>
          </div>
          <div className="task--info--details">
            <span>CREATR MALIK</span>
            <p>
              <img src="../assets/images/hustler-star.png" alt="Rating" /> 4.5
            </p>
          </div>
        </div>
      </div>
      <div className="task--items--edit--delete">
        <div className="delete--taks--items wislishadd">
          <AiOutlineHeart />
        </div>
        <div className="page-two edit--taks--items">
          <button className="custom--btn ligghtbtn-hover transparent--btn">
            <span>Show Interest</span>
          </button>
        </div>
      </div>
    </div>

    <div className="task--items--details">
      <div className="task--detail-full" style={{ width: "100%" }}>
        <h2 className="hover-text" style={{ cursor: "pointer" }}>
       I need my lawn mowing
        </h2>
        <div className="service--category--task">
          <h3> lawn mowing</h3>
        </div>
      </div>
    </div>

    <div className="task--items--content">
      <p>
        <span className="task--items--content--icon">
          <AiOutlineCalendar />
        </span>
        01 Jan 2024 to 10 Jan 2024
      </p>
      <p>
        <span className="task--items--content--icon">
          <TbMoneybag />
        </span>
        Budget: $50.00
      </p>
      <p>
        <span className="task--items--content--icon">
          <GrLocation />
        </span>
        Area: Kamloops
        </p>
    </div>

    <div className="task--items-footer">
      <p>
      My lawn is 100 meter square    
        </p>
    </div>

    <div className="task--items-application">
      <p>
        <img src="../assets/images/hustler--taskdone.png" alt="Applications" />{" "}
        1 Applications
      </p>
    </div>
  </div>
  {/* <div className="task--items-down--footer new--jobs-h">
    <p>
      Please note that this is a <b>monthly</b> job, and payment will be made on
      a <b>monthly</b> basis upon completion.
    </p>
  </div> */}
</div>
  )
}

export default HustlerHomeNewJob


import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    participantDetails: {},
};

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        setParticipantDetails: (state, action) => {
            state.participantDetails = action.payload;
        },
        clearParticipantDetails: (state) => {
            state.participantDetails = {};
        },
        setMsgCount: (state, action) => {
            state.msgcount = action.payload; // Update msgcount
          },
        clearMsgCount: (state) => {
            state.msgcount = 0; // Reset msgcount
        },
      
    },
});

export const {
    setParticipantDetails,
    clearParticipantDetails,
    setMsgCount,
    clearMsgCount
  
} = chatSlice.actions;

export default chatSlice.reducer;

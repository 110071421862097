import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { withoutAuthAxios, authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

import Select from "react-select";
import { IoIosSearch } from "react-icons/io";
import { CanadianCity } from "../../utils/constants";
import DirectorHeader from "./DirectorHeader";
import RecommenedHustler from "./RecommenedHustler";
import Footer from "../../common/footer";
import { State, City } from "country-state-city";
import IsLoggedinHOC from "../../common/IsLoggedInHOC";
import { saveServices, saveTaskOptions } from "../../Redux/Reducers/appSlice";
import { RemovingDuplicatesServicesandCategories, toCamelCase } from "../../utils/helper";

const BecomeDirector = (props) => {
  const navigate = useNavigate();
  const { setLoading } = props;
  const [selectedService, setSelectedService] = useState(null);
  const [categoryList, setcategoryList] = useState([]);
  const [provinceList, setprovinceList] = useState([{
    value: "BC",
    label: "British Columbia"
  }]);
  const [cityList, setcityList] = useState([]);
  const [selectedProvince, setselectedProvince] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [stateCode, setStateCode] = useState("");

  const taskCatgeory = useSelector((state) => state.app?.services);

  const taskServices = useSelector((state) => state.app?.services);
  const dispatch=useDispatch()

  const handleSelectTask = (option) => {
    setSelectedService(option);
  };

  const handleSelectProvince = (e) => {
    if (e) {
      setStateCode(e?.value);
      setselectedProvince(e);
      setSelectedCity([]);
    } else {
      setcityList([]);
      setSelectedCity([]);
      setStateCode("");
      setselectedProvince([]);
    }
  };

  const handleSelectCity = (option) => {
    setSelectedCity(option);
  };

  const handleCaletoryLink = (value) => {
    
    setSelectedService(value.label);
  };

  const categorylist = async () => {
    setLoading(true);
    await authAxios()
      .get("task/list-random-categories")
      .then((response) => {
        setLoading(false);
        const resData = response?.data;
        if (resData.status == 1) {
          setcategoryList(resData?.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);

        toast.error(error?.response?.data?.message);
      });
  };
  

  const getTaskListData = async () => {
    setLoading(true);
    await withoutAuthAxios()
      .get(`/services/get-all-services`)
      .then((response) => {
        const resData = response?.data;
        setLoading(false);
        if (resData?.status == 1) {
          const services = [];
          resData?.data.map((item) => {
            services.push({
              label: toCamelCase(item?.serviceName),
              value: toCamelCase(item?.serviceName),
            });
          });
          const category=[]
          resData?.data?.map((item)=>{
            item?.category?.map((cat)=>{
              category?.push(cat)
            })
          })
          dispatch(saveServices(RemovingDuplicatesServicesandCategories(services)))
          dispatch(saveTaskOptions(RemovingDuplicatesServicesandCategories(category)))
        } else {
          toast.error(resData?.message?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  useEffect(() => {
  //  categorylist();
    getTaskListData()

  }, []);

  // useEffect(() => {

  //   setprovinceList(
  //     State.getStatesOfCountry("CA").map(({ isoCode, name }) => ({
  //       value: isoCode,
  //       label: name,
  //     }))
  //   );

  // }, []);

  // useEffect(() => {
  //   if (stateCode) {

  //     setcityList(
  //       City.getCitiesOfState("CA", stateCode).map(({ name }) => ({
  //         value: name,
  //         label: name,
  //       }))
  //     );
  //   }
  // }, [stateCode]);

  useEffect(()=>{
    setprovinceList(
      [ { value: "BC", label: "British Columbia" },
        {value: "SK",label: "Saskatchewan"}
     ]
      );


  },[])

  useEffect(()=>{
    setcityList([])
    if(stateCode=="BC"){
        setcityList(
          [ {"value": "Kamloops", "label": "Kamloops"},
            {"value": "Kelowna","label": "Kelowna"},
            {"value": "Peachland","label": "Peachland"},
            {"value": "Penticton","label": "Penticton"},
            {"value": "Vernon","label": "Vernon"},
            {"value": "West Kelowna","label": "West Kelowna"},
          ]
        )
      }
      else{
    
        setcityList(
          [ {value: "Saskatoon",label: "Saskatoon"}
          ]
        )
      }
  },[stateCode])

  // const redirectPath = sessionStorage.getItem('redirectPath') || '/home';
  // const redirectPath = sessionStorage.getItem('redirectPath') || '/creatrhome';

  

  // useEffect(()=>{
  //   if (redirectPath === '/your-task-list/completed') {
  //    // Navigate to the path and pass state as a second argument
  //    navigate("/your-task-list", { state: { id: "completed-task" } });
   
  //    // Clear the stored path
  //    sessionStorage.removeItem('redirectPath');
  //  }
  //  else if(redirectPath === '/your-task-list/in-progress'){

  //    navigate("/your-task-list", { state: { id: "in-progress" } });
  //    sessionStorage.removeItem('redirectPath');
  
  //  }
  //  else if(redirectPath === '/your-task-list/in-closed'){
  //    navigate("/your-task-list", { state: { id: "in-closed" } });
  //    sessionStorage.removeItem('redirectPath');
  //  }  
  //  else{
  //   sessionStorage.removeItem('redirectPath');// Clear the stored path
  //   navigate(redirectPath); // Use the navigate function to redirect

  //  }         
  
  
  // },[])

  return (
    <>
      <section
        className="banner--top director--banner"
        style={{
          position: "relative",
        }}
      >
        <div className="container director--container">
          <div className="row">
            <div className="banner--content">
              <h1
                className="text--dark playFairDisplay text-center heading-h1 mb-55"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Book Your Service Now 
              </h1>
              <h5
                className="playFairDisplay text-center  mb-4 mt-0"
              >
                Find The Right Person For Your Job 

              </h5>

              
              <div
                style={{ cursor: "pointer" }}

              onClick={()=>{navigate("/create-task")}}
              // className="Post--A--job
              //   flex justify-center sm-flex-col sm-justify-start "
                className="Post--A--job flex justify-center items-center sm:flex-col sm:justify-start"

              >

                 Click here to post a job now!              
              </div>

              {/* <div
                className="search--bar--banner flex justify-center sm-flex-col sm-justify-start"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-duration="1600"
              >
                <div className="need--select">
                   <input
                    required
                    placeholder="I need help with..."
                    className="need--select custom--input"
                     value={selectedService}

                    onChange={(e) => setSelectedService(e.target.value)}
                  />
                 
                </div>

                <div className="city--zip">
                  <Select
                    options={provinceList}
                    isClearable={false}    
                    className="select--custom province--select"
                    value={selectedProvince}

                    placeholder={`Select Province`}
                    onChange={handleSelectProvince}
                    // isSearchable={false}
                  />
                </div>
                <div className="city--zip">
                  <Select
                    // options={cityList}
                    options={selectedProvince?cityList:[]}

                    //    isClearable
                    isClearable={false}
                    className="select--custom"
                    value={selectedCity}
                    placeholder={`Select City`}
                    onChange={handleSelectCity}
                    // isSearchable={false}
                  />
                </div>

                {selectedService&& selectedCity?.label ? (
                  <Link
                    className={`text-white text-decoration-none btn btn-dark submit--need `}
                    to={`/search-hustlr/${selectedService?.replace(
                      " ",
                      "-"
                    )}/${selectedProvince?.label?.replace(
                      " ",
                      "-"
                    )}/${selectedCity?.label?.replace(" ", "-")}`}
                  >
                    <span>Get Help</span>
                  </Link>
                ) : (
                  <Link
                    className={`text-white text-decoration-none btn btn-dark submit--need disabled`}
                    to={`/search-hustlr/${selectedService?.label?.replace(
                      " ",
                      "-"
                    )}/${selectedProvince?.label?.replace(
                      " ",
                      "-"
                    )}/${selectedCity?.label?.replace(" ", "-")}`}
                  >
                    <span>Get Help</span>
                  </Link>
                )}

             
              </div>
               */}
              {/* <div
                className="category--searchlist"
                data-aos="fade-up"
                data-aos-offset="300"
                data-aos-duration="2000"
              >
                <ul>
                  {taskCatgeory &&
                    taskCatgeory.length > 0 &&
                    taskCatgeory.map((item, index) => (
                      <li key={index} style={{textTransform:'capitalize', cursor:'pointer'}}>
                        <a onClick={() => handleCaletoryLink(item)}>
                        {item.label}
                        </a>
                      </li>
                    ))}
                </ul>
              </div> */}
            </div>
          </div>
        </div>

        <ul className="bubble--container" style={{ zIndex: "1" }}>
          <img
            src="./assets/images/banner--image/img-1.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-2.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-4.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-3.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-5.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-6.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-7.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-8.png"
            class="bubble"
            alt="img-2"
          />
        </ul>
      </section>

      <RecommenedHustler />

      <section
        className="book--your--services--container"
        data-aos="fade-up"
        data-aos-offset="300"
        data-aos-duration="1000"
      >
        <h2 className="text-white playFairDisplay heading-h1">
          Book Your Service Now 
        </h2>

        <button
          className="custom--btn ligghtbtn-hover text-center"
          // onClick={() => navigate("https://sidehustlsupport.zendesk.com/hc/en-us", "_blank")}
          onClick={() => window.open("https://sidehustlsupport.zendesk.com/hc/en-us", "_blank")}

        >
          <span>Get help today</span>
        </button>
      </section>
    </>
  );
};

export default IsLoadingHOC(IsLoggedinHOC(BecomeDirector));

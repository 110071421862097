import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { setReportFormatDate } from "../../assets/Helper";
import { TbMoneybag } from "react-icons/tb";
import { AiOutlineCalendar } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { BudgetFormat, dateFormat, displayBudget } from "../../utils/helper";
import TaskMedia from "../../common/TaskMedia";
import moment from "moment";
import { IoIosArrowRoundBack, IoIosCloseCircle } from "react-icons/io";
// import { MdReviews } from "react-icons/md";
import {
  useNavigate,
} from "react-router-dom";
import { FaEye } from 'react-icons/fa'; // Import Font Awesome icon
import { PiEye } from "react-icons/pi";
import HustlerAddReview from "../../common/HustlerAddReview";
import { useSocket } from "../../contexts/SocketContext";

// import { PiEye } from "react-icons/pi";
import { MdReviews } from "react-icons/md";

const TaskDetailsView = (props) => {
  const navigate = useNavigate();

  const param = useParams();
  const { setLoading } = props;
  const { id } = param;
  const socket = useSocket();
  const [Taskdetails, setTaskdetails] = useState([]);
  const [isVisible, setIsVisible] = useState(true); // State to toggle visibility
  const [showReviewModel, setshowReviewModel] = useState(false);
  const [allId, setallId] = useState({
    directorId: "",
    taskId: "",
    subscriptionId: "",
    intervalId: "",
  });
  console.log(allId,"allId......")
  const [taskReviewDetails, settaskReviewDetails] = useState({});
  const [SensitiveWords, setSensitiveWords] = useState([]);
  console.log(Taskdetails,"Taskdetails............")
// Function to toggle visibility
const toggleVisibility = () => {
  setIsVisible(!isVisible);
};

  const fetchTask = async () => {
    setLoading(true);
    await authAxios()
      .get(`task/get-specific-task/${id}`)
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          setTaskdetails(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  const [review,setReview]=useState()

  const GetReview = async () => {
    setLoading(true);
    await authAxios()
      .get(`/review/get-user-review/${id}`)
      .then((response) => {
        const resData = response?.data;
        // console.log(resData,"resDataresData")
        setReview(resData?.data)
        setLoading(false);
        settaskReviewDetails(resData?.data[0]);
        if (resData.status == 1) {
          // setTaskdetails(resData.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error?.response?.data?.message);
      });
  };

  // useEffect(() => {
  //   fetchTask();
  // }, [id]);

useEffect(()=>{
  GetReview();
  fetchTask();

},[id])


const handleViewOpenModal = (item) => {
  fetchSpecificTaskReview(item?._id);
  setshowReviewModel(true);
};
  
const handleAddOpenModal = (Taskdetails) => {
  setallId((prev) => ({
    ...prev,
    directorId: Taskdetails?.user,
    taskId: Taskdetails?._id,
  }));
  setshowReviewModel(true);

};


const fetchSpecificTaskReview = async (id) => {
  setLoading(true);
  await authAxios()
    .get(`/review/specific-task-reviews/${id}`)
    .then((response) => {
      setLoading(false);
      const resData = response?.data;
      if (resData.status == 1) {
        settaskReviewDetails(resData?.data[0]);
      } else {
        toast.error(resData?.message);
      }
    })
    .catch((error) => {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    });
};


const handleSubmitReview = async (item) => {
  const payload = {
    reviewReceiver: allId.directorId,
    rating: item.rating,
    behavior: item.behavior,
    availability: item.availability,
    payment: item.payment,
    communication: item.communication,
    comment: item.comment,
    taskId: allId.taskId,
  };
console.log(payload,"dataAdd review")
  setLoading(true);
  await authAxios()
    .post("/review/create-review", payload)
    .then((response) => {
      const resData = response?.data;
      setLoading(false);
      if (resData.status == 1) {
        socket.emit("hustlerReviewed", resData?.data.review);
        toast.success(resData?.message);
        // fetchCompletedtask(1);
        setshowReviewModel(false);
      } else {
        toast.error(resData?.message);
      }
    })
    .catch((error) => {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    });
};

const handleEditReview = async (item) => {
  const payload = {
    rating: item.rating,
    behavior: item.behavior,
    availability: item.availability,
    payment: item.payment,
    communication: item.communication,
    comment: item.comment,
  };
  const taskId = item.taskId;
  setLoading(true);
  await authAxios()
    .put(`/review/update-review/${taskId}`, payload)
    .then((response) => {
      const resData = response?.data;
      setLoading(false);
      if (resData.status == 1) {
        toast.success(resData?.message);
        // fetchCompletedtask(1);
        settaskReviewDetails([]);

        setshowReviewModel(false);
      } else {
        toast.error(resData?.message);
      }
    })
    .catch((error) => {
      setLoading(false);
      toast.error(error?.response?.data?.message);
    });
};

  return (
    <>
      <div>
        <section
          className="director--search--hustler pt-117 hustler--home--page"
          style={{ minHeight: "400px" }}
        >
          <div className="custom--container m-auto pt-5 pb-5">
            <div className="container--filter">
              <div className="filter--data--search" style={{maxWidth: "100%"}}>
                <div className="task--lists--profile--based">
                  <div className="director--list--task">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        
                        <div className="list--tasks upper--task--list">

                        <div
                              className="flex"
                              style={{ minWidth: "150px" }}
                              // onClick={goBack}
                              onClick={() => {
                                if (Taskdetails?.status == "open") {
                                  // navigate("/home");
                                  navigate("/hustlerhome");

                                 
                                } else if (Taskdetails?.status == "in-progress") {
                                  navigate("/hustlr-jobs", { state: { id: "upcoming" } });
                                } else if (Taskdetails?.status == "completed") {
                                  navigate("/hustlr-jobs", { state: { id: "completed-jobs" } });
                                }
                              }}
                            >

                              <button
                                className="custom--btn mb-3 darkbtn-hover mini-btn"
                                style={{ marginLeft: "auto" }}
                              >
                                <span>
                                  <IoIosArrowRoundBack /> Back
                                </span>
                              </button>
                            </div>

                          <div className="created--task--lists p-0 bg-white border-0">
                            <h1
                              className="text--dark playFairDisplay heading-h1 mb-3"
                              style={{ fontSize: "clamp(35px, 3vw, 50px)" }}
                            >
                              {Taskdetails.title}
                            </h1>
                            <div
                              className="task--items--header"
                              style={{
                                flexDirection: "initial",
                                flexWrap: "wrap",
                              }}
                            >
                              {/* {Taskdetails.serviceCategories &&
                                Taskdetails.serviceCategories.map(
                                  (item, index) => <h3 key={index}>{item}</h3>
                                )} */}
                                {Taskdetails?.serviceName && (<h3>{Taskdetails?.serviceName}</h3>)}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
{/* ========================================= */}

{/* {console.log(Taskdetails, "task is testing ")}
status
: 
"completed" */}

{Taskdetails?.status=="completed"?
<div
                                    className="task--items--edit--delete"
                                    // style={{ maxWidth: "fit-content" }}
                                  >
                                    {Taskdetails?.hustlerReviewed ? (
                                      <button
                                        onClick={() =>
                                          handleViewOpenModal(Taskdetails)
                                        }
                                        className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                      >
                                        <span>
                                          <PiEye /> My Review
                                        </span>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() =>{ handleAddOpenModal(Taskdetails);
                                          settaskReviewDetails(0);
                                        }}
                                        className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                      >
                                        <span>
                                          <MdReviews /> Add Review
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                  :""
}


                    {/* //===================================== */}
                    {review?.map((reviewItem, index)=>(        
                      <div id="reviews-profile">
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div></div>
       <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center'}} >       
       <FaEye
          style={{ cursor: 'pointer', color: 'black' ,fontSize:"28px" }} // Black eye icon
          onClick={toggleVisibility} // Click to toggle visibility
        />

&nbsp;&nbsp;&nbsp;
{/* <h3>Review  hustler for this job</h3> */}
<h4> Hustlr Review</h4>


        </div>

      </div>

      {/* Conditionally render the comments section */}

      

      {isVisible && (
        <div className="comments--profile--lists">
          <div className="reviews--section">
            {/* <div className="no--comments">
              <h5 style={{ textAlign: 'center', color: '#ada7a7' }}>No Reviews</h5>
            </div> */}

            <div className="user--comment--items">
              <div className="user--comment--header">
                <div className="user--comment--details">
                  <img
                    // src="profile_image_placeholder.jpg"
                    src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${reviewItem?.reviewSender?.profilePic}`}

                    alt=""
                    className="user--profile--img"
                    style={{
                      height: '80px',
                      width: '80px',
                      borderRadius: '10px',
                      objectFit: 'cover',
                    }}
                  />
                  <div className="user--comment--detail">
                    {/* <h3>John Doe</h3> */}
                    <h3>{`${reviewItem.reviewSender.fname} ${reviewItem.reviewSender.lname}`}</h3>

                    <span className="user--rating--comment">
                      <img src="/assets/images/hustler-star.png" alt="rating" />
                      {reviewItem.rating}

                    </span>
                    <span>
                    {" "}{" "}
                                {setReportFormatDate(reviewItem?.createdAt)}
                    {/* <span>{new Date(reviewItem.createdAt).toLocaleDateString()} */}

                    </span>

                  </div>
                </div>
                <div className="view--task--comment">
                  <span style={{ cursor: 'pointer' }}>
                    <svg width="24" height="24"></svg>
                  </span>
                </div>
              </div>

              <div className="user--comment--description">
                <p>{reviewItem?.comments}</p>
                {/* <span
                  className="read-more-toggle"
                  style={{
                    cursor: 'pointer',
                    color: '#1f1f28',
                    textDecoration: 'underline',
                    fontWeight: '600',
                  }}
                >
                  Read More
                </span> */}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>))}

 {/* /====================================================== */}

 {/* <div
                                    className="task--items--edit--delete"
                                    // style={{ maxWidth: "fit-content" }}
                                  >
                                    {Taskdetails?.hustlerReviewed
                                    ? (
                                      <button
                                        // onClick={() =>
                                        //   handleViewOpenModal(item)
                                        // }
                                        className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                        // style={{ maxWidth: "fit-content" }}
                                      >
                                        <span>
                                          <PiEye /> My Review 
                                        </span>
                                      </button>
                                    ) : (
                                      <button
                                  
                                        className="custom--btn ligghtbtn-hover transparent--btn minbutton icon--btn"
                                      >
                                        <span>
                                          <MdReviews /> Add Review
                                        </span>
                                      </button>
                                    )}
                                  </div> */}
 {/* //===================================== */}

                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    ></ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        <div className="list--tasks">
                          <div className="created--task--lists bg-white border-0 p-0">
                            <div className="task--items-down--footer task--para pb-2 pt-4">
                              <p>
                                <span className="task--items--content--icon">
                                  <TbMoneybag />
                                </span>
                                {/* Budget: $ */}
                                {/* {BudgetFormat(`${Taskdetails?.budget}`)} */}
                                {Taskdetails?.budget == 0?
                                   <>
                                    Budget: Open to Offer
                                    </>
                                    :
                                    <>

                                    Budget: $
                                    {BudgetFormat(
                                      `${Taskdetails?.budget}`
                                    )}
                                    </>
                                  }
                                {/* | Members: 05 */}
                              </p>
                              <p>
                                <span className="task--items--content--icon">
                                  <AiOutlineCalendar />
                                </span>
                                Job Date:{" "} 
                                {/* {dateFormat(Taskdetails.dueDate)} */}
                                { Taskdetails?.dueDate ?
                             (
                              <> {setReportFormatDate(Taskdetails?.dueDate)} to  {setReportFormatDate(Taskdetails?.endDate)}</>)
                             :(setReportFormatDate(Taskdetails?.dueDate))
                             }
                               
                              </p>
                              <p>
                                <span className="task--items--content--icon">
                                  <GrLocation className="location" />
                                </span>
                                Area: {Taskdetails.cityName}
                              </p>
                            </div>
                          </div>

                          {Taskdetails?.finalPrice ||
                          Taskdetails?.updatedDate ? (
                            <div
                              className="created--task--lists border-0"
                              style={{
                                padding: "15px",
                                backgroundColor: "#f5f5f5",
                                marginBottom: "0px",
                              }}
                            >
                              <div className="task--items-down--footer task--para pt-0 final--biding">
                                {Taskdetails?.finalPrice && (
                                  <>
                                  {Taskdetails.oneTimePayment==true && <p
                                    className={`${
                                      Taskdetails?.updatedDate ? "mb-0" : "mb-0"
                                    }`}
                                  >
                                    <span className="task--items--content--icon">
                                    <AiOutlineCalendar />
                                    </span>
                                    <b>Final Date:</b>
                                    <span>{dateFormat(  Taskdetails?.latestCounterOffer
                                          ?.finalDate?Taskdetails?.latestCounterOffer
                                          ?.finalDate:Taskdetails?.taskProposal?.proposalDate) }</span>
                                  </p> }
                                  
                                  {/* <br/> */}

                                  <p
                                    className={`${
                                      Taskdetails?.updatedDate ? "mb-0" : "mb-0"
                                    }`}
                                  >
                                    <span className="task--items--content--icon">
                                      <TbMoneybag />
                                    </span>
                                    <b>Final Bid:</b> $
                                    {BudgetFormat(`${Taskdetails?.finalPrice}`)}
                                    {/* | Members: 05 */}
                                  </p>


                                  </>
                                )}
                                
                              </div>
                            </div>
                          ) : null}

                          <TaskMedia imagePaths={Taskdetails?.attachments} />

                          <div className="created--task--lists p-0 border-0 bg-white pb-3 mb-0">
                            <div className="task--items-footer">
                              <h3 class="playFairDisplay" style={{fontSize:"22px"}}>Job Description:</h3>
                              <p>{Taskdetails?.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {showReviewModel && (
        <HustlerAddReview
          SensitiveWords={SensitiveWords}
          setshowReviewModel={setshowReviewModel}
          taskReviewDetails={taskReviewDetails}
          settaskReviewDetails={settaskReviewDetails}
          handleSubmitReview={handleSubmitReview}
          handleEditReview={handleEditReview}
        />
      )}
    </>
  );
};

export default IsLoadingHOC(TaskDetailsView);

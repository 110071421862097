import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../common/IsLoadingHOC";
import { setReportFormatDate } from "../../assets/Helper";
import { TbMoneybag } from "react-icons/tb";
import { AiOutlineCalendar } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { BudgetFormat, dateFormat, displayBudget } from "../../utils/helper";
import TaskMedia from "../../common/TaskMedia";
import moment from "moment";
const TaskJobInfo = (props) => {
  const param = useParams();
  const { setLoading } = props;
  const { id } = param;

  const [Taskdetails, setTaskdetails] = useState([]);

  const fetchTask = async () => {
    setLoading(true);
    await authAxios()
      .get(`task/get-specific-task/${id}`)
      .then((response) => {
        const resData = response.data;
        setLoading(false);
        if (resData.status == 1) {
          setTaskdetails(resData.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    fetchTask();
  }, []);
  return (
    <>
      <div>
        <section
          className="director--search--hustler pt-117 hustler--home--page"
          style={{ minHeight: "400px" }}
        >
          <div className="custom--container m-auto pt-5 pb-5">
            <div className="container--filter">
              <div className="filter--data--search">
                <div className="task--lists--profile--based">
                  <div className="director--list--task">
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        <div className="list--tasks upper--task--list">
                          <div className="created--task--lists p-0 bg-white border-0">
                            <h1
                              className="text--dark playFairDisplay heading-h1 mb-3"
                              style={{ fontSize: "clamp(35px, 3vw, 50px)" }}
                            >
                              {Taskdetails.title}
                            </h1>
                            <div
                              className="task--items--header"
                              style={{
                                flexDirection: "initial",
                                flexWrap: "wrap",
                              }}
                            >
                              {Taskdetails.serviceCategories &&
                                Taskdetails.serviceCategories.map(
                                  (item, index) => <h3 key={index}>{item}</h3>
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul
                      className="nav nav-pills mb-3"
                      id="pills-tab"
                      role="tablist"
                    ></ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active"
                        id="upcoming"
                        role="tabpanel"
                        aria-labelledby="upcoming-tab"
                      >
                        <div className="list--tasks">
                          <div className="created--task--lists bg-white border-0 p-0">
                            <div className="task--items-down--footer task--para pb-2 pt-4">
                              {Taskdetails?.budget==0?<p>
                                <span className="task--items--content--icon">
                                  <TbMoneybag />
                                </span>
                                Budget: open to offer
                                {/* {BudgetFormat(`${Taskdetails?.budget}`)} */}
                                {/* | Members: 05 */}
                              </p>
                               :
                              <p>
                                <span className="task--items--content--icon">
                                  <TbMoneybag />
                                </span>
                                Budget: $
                                {BudgetFormat(`${Taskdetails?.budget}`)}
                                {/* | Members: 05 */}
                              </p>}

                              
                            {Taskdetails.endDate?
                              <p>
                                <span className="task--items--content--icon">
                                  <AiOutlineCalendar />
                                </span>
                                Job Date:{" "} {dateFormat(Taskdetails.dueDate)} to {dateFormat(Taskdetails.endDate)} 
                              </p>
                              :
                              <p>
                              <span className="task--items--content--icon">
                                <AiOutlineCalendar />
                              </span>
                              Job Date:{" "} {dateFormat(Taskdetails.dueDate)} 
                            </p>

}


                              <p>
                                <span className="task--items--content--icon">
                                  <GrLocation className="location" />
                                </span>
                                Area: {Taskdetails.cityName}
                              </p>
                            </div>
                          </div>

                          <TaskMedia imagePaths={Taskdetails?.attachments} />

                          <div className="created--task--lists p-0 border-0 bg-white pb-3 mb-0">
                            <div className="task--items-footer">
                            <h3 class="playFairDisplay" style={{fontSize:'22px'}}>Job Description:</h3>
                              <p>{Taskdetails?.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default IsLoadingHOC(TaskJobInfo);

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { BsBell } from "react-icons/bs";
import { LiaUserCircleSolid } from "react-icons/lia";
import { useDispatch, useSelector } from "react-redux";
import { logout, setallnotifications } from "../Redux/Reducers/authSlice";
import { toast } from "react-toastify";
import { socketService } from "./../services/socket";
import { FaSquare } from "react-icons/fa";
import { MdLogout } from "react-icons/md";
import { authAxios } from "../services/config";
import { useRef } from "react";
import Notifications from "../common/Notifications";
import { clearParticipantDetails,setMsgCount } from "../Redux/Reducers/chatSlice";
import { FaUser, FaBriefcase, FaStar } from "react-icons/fa6";
import { IoHomeOutline, IoSettingsOutline } from "react-icons/io5";
import { PiFolderSimplePlusLight } from "react-icons/pi";
import { FaPlay } from "react-icons/fa"; // Import an icon, FaPlay is just an example
import Joyride, { EVENTS, STATUS } from 'react-joyride';
import { useTour } from '../contexts/TourContext';




// import Joyride from "react-joyride";
import { FALSE } from "sass";
import { useSocket } from "../contexts/SocketContext";
// import { useSocketrr } from "../contexts/";




const CommonHeader = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { startTour } = useTour();

  const socket = useSocket();


  const location = useLocation();
  const CurrentPath = location.pathname;
  const user = useSelector((state) => state.auth.user);
  // console.log(user.userType,"user shubham is testing now ")
  const [arrivalMessage, setArrivalMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const [profileViewDropdown, setProfileViewDropdown] = useState(false);
  const [activeOverlayMenu, setActiveOverlayMenu] = useState(false);
  const dropdownRef = useRef(null);

  const msgcount = useSelector((state) => state.chat.msgcount);


  const [userDetails, setuserDetails] = useState({
    personalDetails: {
      fname: "",
      lname: "",
      profilePic: "",
    },
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setProfileViewDropdown(false);
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);


  const fetchUserDetails = async () => {
    await authAxios()
      .get("profile/view-profile")
      .then((response) => {
        const resData = response?.data;
        if (resData.status == 1) {
          setuserDetails(resData?.data);
        } else {
          toast.error(resData?.message);
        }
      })
      .catch((error) => {
        toast.error(error?.response?.data?.message);
      });
  };


  useEffect(() => {
    if (arrivalMessage) {
      const existData = messages.find(
        ({ userId, task_id }) =>
          userId === arrivalMessage.userId && arrivalMessage.task_id
      );
      if (!existData){
        setMessages((prev) => [...prev, arrivalMessage]);
      }
    }
    fetchUserDetails();
  }, [arrivalMessage]);

  useEffect(() => {
    socketService.connect().then(async (socket) => {
      await socket.on("accepted-task", (data) => {
        setArrivalMessage(data);
      });
    });
  }, []);

  const handleLogout = () => {
    dispatch(logout());
    toast.success("Logout successful");
    dispatch(setallnotifications([]))
    dispatch(clearParticipantDetails());
    localStorage.removeItem("CityName")
    localStorage.removeItem("Province")
    localStorage.removeItem("Keyword")
    sessionStorage.removeItem('subscriptionDetails')
    sessionStorage.removeItem('paymentDetails')
    sessionStorage.removeItem('redirectPath1')
    // alert("hi123")

if (user?.userType === "HUSTLER") {
  navigate("/login", { state: { id: "hustler" } });
} else {
  navigate("/login");
}
    // navigate("/login", { state: { id: "hustler" } });
  };

  const handleProviewViewDropdown = () => {
    fetchUserDetails()
    setProfileViewDropdown(!profileViewDropdown);
  };


  const handleMenuOverlayButton = () => {
    setActiveOverlayMenu(!activeOverlayMenu);
  };

  const handleMenuOverlayClose = () => {
    setActiveOverlayMenu(false);
  };

  useEffect(() => {
    setActiveOverlayMenu(false);
  }, [location]);

  const [run, setRun] = useState(false); // Controls whether the tour is running

  useEffect(() => {
    if (CurrentPath === "/create-task") {
      document.querySelector(".main--header").style.position = "fixed";
      document.querySelector(".main--header").style.backgroundColor = "white";
          
    } 
    else {
      document.querySelector(".main--header").style.position = "absolute";
      document.querySelector(".main--header").style.backgroundColor = "";
    }
  }, [CurrentPath]);

  const [storedUnreadCount, setStoredUnreadCount] = useState(0);

    const fetchUnreadMessages = async () => {
      try {
        const response = await authAxios().get(`/chats/unread-messages`);
        const total=0
        const totalMessages = response.data.data.reduce((total, item) => total + item.unreadMessages, total);
        console.log(totalMessages,"totalMessages123")
        dispatch(setMsgCount(totalMessages));

        setStoredUnreadCount(totalMessages);

        const unreadCounts = {};
        response.data.data.forEach((chat) => {
          const { chatId, unreadMessages } = chat;
          unreadCounts[chatId] = unreadMessages;
        });

      } catch (error) {
        console.error("Error fetching unread message counts:", error);
      }
    };
    // localStorage.setItem("totalUnreadCount", totalUnreadCount);

    // Retrieve and log the value from local storage
    // const UnreadCount = localStorage.getItem("totalUnreadCount");

const [UnreadCount,setUnreadCount ]=useState()
// setInterval(() => {
      // const count = localStorage.getItem("totalUnreadCount");
      // console.log(count,"count")
      // setUnreadCount(count); // Parse count as integer or default to 0
// },1000);
    
// useEffect(()=>{
//   setStoredUnreadCount(UnreadCount);

// },[UnreadCount])


  useEffect(() => {
    // fetchUnreadMessages()
    const handleNewNotification = (notification) => {
      // fetchUnreadNotifications();
      fetchUnreadMessages()
      // dispatch(setallnotifications([notification, ...allUnreadNotifications]))
      //   setMessages((prevMessages) => [...prevMessages, notification]);
    };

    if (socket) {
      socket.on("newNotification", handleNewNotification);
    }

    return () => {
      if (socket) {
        socket.off("newNotification", handleNewNotification);
      }
    };
  }, [socket]);

  useEffect(()=>{
    fetchUnreadMessages()
  },[])


  return (
    <>
      <div className="header--director">
        <header
          className={`main--header`}
          data-aos="fade-in"
          data-aos-duration="1500"
          style={{ backgroundColor: activeOverlayMenu ? "var(--white)" : "" }}
        >
          <div className="max-w-1920 flex items-center justify-between sm-flex-col">
            <div className="main--nav">
              <nav className="navbar navbar-expand-lg navbar-light">
                <div className="navbar-brand">
                  <Link to="/">
                    <img
                      src="/assets/images/logo.png"
                      alt="logo"
                      onClick={() => dispatch(clearParticipantDetails())}
                    />
                  </Link>
                  <p className="user--type">{user?.userType === "DIRECTOR" ? 'Creatr' : 'Hustlr'} </p>
                </div>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                  onClick={handleMenuOverlayButton}
                >
                <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className={`collapse navbar-collapse ${activeOverlayMenu === true ? "show" : ""
                    }`}
                  id="navbarNav"
                >
                  <ul className="navbar-nav ml-auto">
                    <li
                      style={{
                        borderBottom:
                          CurrentPath === "/creatrhome" || CurrentPath === "/hustlerhome"
                            ? "2px solid black"
                            : "none",
                      }}
                      className="nav-item"
                      onClick={() => dispatch(clearParticipantDetails())}
                    >
                      <Link className="nav-link my-first-step first-step" 
                      // to={user?.userType === "DIRECTOR" ?"/creatrhome":"/hustlerhome"}
                      to={user?.userType === "DIRECTOR" ? "/creatrhome" : "/hustlerhome"}

                      
                      >
                        {user?.userType === "DIRECTOR" ? 'Home' : 'New Jobs'}
                      </Link>
                    </li>

                    {user?.userType === "HUSTLER" && (
                      <li className="nav-item second-step">
                        <Link
                          onClick={() => dispatch(clearParticipantDetails())}
                          className="nav-link"
                          to="/hustlr-jobs"
                          style={{
                            borderBottom:
                              CurrentPath === "/hustlr-jobs"
                                ? "2px solid black"
                                : "none",
                          }}
                        >
                          Your Jobs
                        </Link>
                      </li>
                    )}

                    <li
                      className="nav-item"
                      style={{
                        borderBottom:
                          CurrentPath === "/messages"
                            ? "2px solid black"
                            : "none",
                      }}
                    >

                      <Link className=" page-four-one page-nine-element-two nav-link my-second-step third-step"  to="/messages">
                        Messages

                       {msgcount? <sup 
                       className="notification-msg-dot"
                        // style={{ marginLeft: "5px", fontSize: "0.8em", color: "white" }}
                        >
                        {msgcount}
                        </sup>:""
}

                      </Link>
                    </li>
                    {user?.userType === "DIRECTOR" && (
                      <li
                        className="nav-item"
                        style={{
                          borderBottom:
                            CurrentPath === "/hustlrs-lists"
                              ? "2px solid black"
                              : "none",
                        }}
                      >
                        <Link
                          className="page-fourteen-element nav-link my-third-step"
                          to="/hustlrs-lists"
                          onClick={() => dispatch(clearParticipantDetails())}
                        >
                          Hustlrs
                        </Link>
                      </li>
                    )}

                    {user?.userType === "DIRECTOR" && (
                      <li className="page-twenty-one-element nav-item">
                        <Link
                          onClick={() => dispatch(clearParticipantDetails())}
                          className="nav-link my-fourth-step  "
                          to="/your-task-list"
                          style={{
                            borderBottom:
                              CurrentPath === "/your-task-list"
                                ? "2px solid black"
                                : "none",
                          }}
                        >
                          Your Jobs
                        </Link>
                      </li>
                    )}

                    <li
                      className="page-twenty-two-element nav-item my-fifth-step fourth-step"
                      onClick={() => window.open('https://sidehustlsupport.zendesk.com')}
                    >
                      <Link className="nav-link" >
                        Help Center
                      </Link>
                    </li>
                    
                     {/* {user?.userType === "DIRECTOR"? */}

                    <li
                      className="mobile-only page-twenty-element page-eight nav-item my-fifth-step fourth-step 
                     hide-in-mobile
                      "
                      // onClick={() => handleStartTour(true)}
                      onClick={startTour}
                      >
                      <Link className="nav-link" >
                      Site Tour                      
                      </Link>
                    </li>
                    
                    <style>
    {`
      @media (max-width: 768px) {
        .hide-in-mobile {
          display: none;
        }
        .show-in-mobile {
          display: block;
        }
      }
      @media (min-width: 769px) {
        .hide-in-mobile {
          display: block;
        }
        .show-in-mobile {
          display: none;
        }
      }
    `}
  </style>

                    <li
                      className="nav-item hide-menu mobile--item--nav "
                      onClick={() => dispatch(clearParticipantDetails())}
                    >
                      <span
                        className="nav-link c-pointer"
                        onClick={handleLogout}
                      >
                        <MdLogout /> Logout
                      </span>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
            <div className="sign--btn flex items-center hide--mb">
              {user?.userType === "DIRECTOR" && (
                <Link to="/create-task" className="btn btn-dark darkbtn-hover page-one-element">
                  <span>Post a Job</span>
                </Link>
              )}
              
               <div className="page-five my-seven-step page-three-element fifth-step 
               " >
              <Notifications />
              </div>

              <div className="profile--dropdown c-pointer my-eight-step six-step " ref={dropdownRef}>
                <LiaUserCircleSolid
                  className="nav-link"
                  onClick={handleProviewViewDropdown}
                />
              </div>
            </div>
          </div>
        </header>

        {profileViewDropdown && (
          <div className="notification--lists profile--view--dropdown">
            <ul>
              <div className="profile--info-detail">
                <img

                  src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${userDetails.personalDetails.profilePic}`}
                />
                <p style={{wordWrap:'break-word'}}>
                  {userDetails.personalDetails.fname}{" "}
                  {userDetails.personalDetails.lname}
                </p>
              </div>
              <li>
                <Link to="/profile" className="dropdown-item c-pointer">
                {/* <FaUser style={{ fontSize: '2px' }} /> Profile */}
                <FaUser style={{ width: '20px', height: '18px', marginTop:'2px' }} />Profile 

                </Link>
              </li>
              
              <li>
                <Link to="/ChangePassword" className="dropdown-item c-pointer">
                  <IoSettingsOutline /> Change Password 
                </Link>
              </li>


              <li>
                <span
                  className="dropdown-item c-pointer"
                  onClick={handleLogout}
                >
                  <MdLogout /> Logout
                </span>
              </li>
            </ul>
          </div>
        )}
      </div>
      {activeOverlayMenu && (
        <div className="overlay--menu" onClick={handleMenuOverlayClose}></div>
      )}

      <div className="sticky--header--mobile">
        <nav>
          <Link to="/" class="nav-item">
            <IoHomeOutline /> <span>Home</span>
          </Link>

          {user?.userType === "DIRECTOR" && (
            <Link class="nav-item" to="/create-task">
              <PiFolderSimplePlusLight /> <span>Create A Job</span>
            </Link>
          )}
          <div className="nav-item" style={{ overflow: "hidden" }}>
            <Notifications /> <span>Notifications</span>
          </div>
          <Link class="nav-item" to="/profile">
            <LiaUserCircleSolid /> <span>Profile</span>
          </Link>
        </nav>
      </div>
    </>
  );
};
export default CommonHeader;

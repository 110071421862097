import React, { useEffect, useRef, useState } from "react";
import { authAxios } from "../services/config";

import { BsBell } from "react-icons/bs";
// import { FaSquare } from "react-icons/fa";
import { FaSquare, FaCheckSquare } from "react-icons/fa";

import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSocket } from "../contexts/SocketContext";
import { IoCloseCircle } from "react-icons/io5";
import {
  setallnotifications,
  setnoficationLength,
} from "../Redux/Reducers/authSlice";
import { setParticipantDetails } from "../Redux/Reducers/chatSlice";
import { Tooltip } from "react-tooltip";
// import { useTour } from '../context/TourContext';
import { useTour } from '../contexts/TourContext';
import { TRUE } from "sass";



const Notifications = () => {
  // const { currentStepIndex, isTourRunning } = useTour();
  const { currentStepIndex, isTourRunning, steps, nextStep, resetTour ,YesPopup,HustlerYesPopup, setHustlerYesPopup} = useTour();



  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const [messages, setMessages] = useState([]);
  const [notificationDropdown, setNotificationDropdown] = useState(false);
  const navigate = useNavigate();
  const [clickedItems, setClickedItems] = useState([]); // Store clicked item IDs

  const socket = useSocket();
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const allUnreadNotifications = useSelector(
    (state) => state?.auth?.allNotifications
  );

  const totalnotificationslength = useSelector(
    (state) => state?.auth.noficationLength
  );

  const fetchUnreadNotifications = async () => {
    try {
      const response = await authAxios().post(
        `/notifications/get-all-notification?page=${1}&limit=${5}`,
        {
          notificationsStatus: false,
        }
      );

      if (response?.data && response?.data?.status === 1) {
        dispatch(setallnotifications(response?.data?.data));
        dispatch(setnoficationLength(response.data.count.total));
      }
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setNotificationDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const handleNewNotification = (notification) => {
      fetchUnreadNotifications();
      // dispatch(setallnotifications([notification, ...allUnreadNotifications]))
      //   setMessages((prevMessages) => [...prevMessages, notification]);
    };

    if (socket) {
      socket.on("newNotification", handleNewNotification);
    }

    return () => {
      if (socket) {
        socket.off("newNotification", handleNewNotification);
      }
    };
  }, [user?.userId, socket]);

  const handleNotificationDropdown = () => {
    setNotificationDropdown(!notificationDropdown);
  };

  const handleNotificationClick = async (notificationId) => {
    if (!clickedItems.includes(notificationId)) {
      setClickedItems((prev) => [...prev, notificationId]); // Add ID to clicked items array
      // // Handle the click logic here (e.g., mark as seen)
    }

    try {
      await authAxios().post(
        `/notifications/mark-notification-seen/${notificationId}`
      );

      fetchUnreadNotifications();
    } catch (error) {
      console.error("Error marking notification as seen:", error);
    }
  };

  useEffect(() => {
    fetchUnreadNotifications();
  }, []);

  const handleSendDirectorMessage = (item) => {

    const payload = {
      participant_id: item.otherParticipants?._id,
      taskId: item.taskId?._id,
      profilePic: item?.otherParticipants?.profilePic,
      participant_name:
        item.otherParticipants?.fname + " " + item.otherParticipants?.lname,
      showWelcomeMessage: true,
    };


    dispatch(setParticipantDetails(payload));
    navigate("/messages");
  };

  useEffect(() => {
    setNotificationDropdown(false);
  }, [location]);

  const [checkedItems, setCheckedItems] = useState({});

  const handleCheckboxClick = (itemId) => {
    setCheckedItems((prevCheckedItems) => ({
      ...prevCheckedItems,
      [itemId]: !prevCheckedItems[itemId], // Toggle checkbox state
    }));
  };

  // Handle notification click logic
  // const handleNotificationClick = (itemId) => {
  //   // Your existing logic
  // };

  //   useEffect(()=>{
  //   if(YesPopup==2){

  //     setNotificationDropdown(true)
  //   }


  // },[YesPopup])
      
  //     useEffect(()=>{
  //       console.log(HustlerYesPopup,"HustlerYesPopup..HustlerYesPopup")
  //       if(HustlerYesPopup==4){
  //       setNotificationDropdown(true)
  //       }
  //       else{
  //         setNotificationDropdown(false)
  //       }
  //     },[HustlerYesPopup])


  return (
    <>
      <div className="notification" onClick={handleNotificationDropdown}>
        <BsBell ref={dropdownRef} />

        {totalnotificationslength > 0 && (
          <>
            {totalnotificationslength && totalnotificationslength > 0 && (
              <div className="notification-dot">
                {totalnotificationslength > 99 ? (
                  <>
                    99<span className="plus--notification">+</span>
                  </>
                ) : (
                  totalnotificationslength
                )}
              </div>
            )}
          </>
        )}
      </div>

      {
      notificationDropdown
      // true
      // true
      && (
        <div className="notification--lists" ref={dropdownRef}>
          <div
            className="close--notifications"
            onClick={() => setNotificationDropdown(false)}
          >
            <IoCloseCircle />
          </div>
          <ul
            style={{
              paddingBottom: `${allUnreadNotifications?.length > 0 ? "0px" : "25px"
                }`,
            }}
          >
            <div className="list--notfication--container">
              {user.userType == "DIRECTOR" ? 

              (
                <>
                {/* //========================================================================================= */}
              {/* {  isTourRunning ? <li>
      <div className="tooltip--task--details">
        {false ? (
          <FaCheckSquare
            data-tooltip-id="task--detail-id"
            data-tooltip-content="Marked as Seen"
            style={{ color: "#ffffff" }}
          />
        ) : (
          <FaSquare
            data-tooltip-id="task--detail-id"
            data-tooltip-content="Mark Seen"
          />
        )}
      </div>
      <span className="dropdown-item page-three-element">
      
 New job proposal from HUSTLR PROFILE on task I need  my lawn mowing.
      </span>
    </li>:""} */}
{/* //========================================================================================= */}

                  {allUnreadNotifications.map((item, index) => (
                    <>


                      {item?.message?.includes("message") ||
                        item?.message?.includes("image") ? 
                        (
                        <li key={item?.id || index} onClick={() => handleNotificationClick(item._id)}>
                          <Tooltip
                            id="task--detail-id"
                            className="tooltip--task--details"
                          />
                          {clickedItems.includes(item._id) ? (
                          <FaCheckSquare // Render the checkmark icon after one click
                              data-tooltip-id="task--detail-id"
                              data-tooltip-content="Marked as Seen"
                              style={{ color: '#fffff' }} // Change the color to green for a visual indicator
                            />
                          ) : (
                              <FaSquare
                                      data-tooltip-id="task--detail-id"
                                      data-tooltip-content="Mark Seen"
                                      onClick={() => handleNotificationClick(item._id)}
                                    />
                                  )}
                            <span
                            onClick={() => handleSendDirectorMessage(item)}
                            className="dropdown-item "
                          >
                          {item?.message?.length > 80 ? item.message.substring(0, 80) + '...' : item.message}
                          </span>
                        </li>
                            )
                             :
                          (
                        <li key={item?.id || index} onClick={() => handleNotificationClick(item._id)} >
                          <Tooltip
                            id="task--detail-id"
                            className="tooltip--task--details"
                          />


{clickedItems.includes(item._id) ? (
           <FaCheckSquare // Render the checkmark icon after one click
              data-tooltip-id="task--detail-id"
              data-tooltip-content="Marked as Seen"
              style={{ color: '#fffff' }} // Change the color to green for a visual indicator
            />
          ) : (

       <FaSquare
              data-tooltip-id="task--detail-id"
              data-tooltip-content="Mark Seen"
              onClick={() => handleNotificationClick(item._id)}
            />
          )}
                          <Link
                            to={{
                              // pathname: "/your-task-list",
                              // pathname: `/task/${item?.taskId?._id}`,
                              pathname: item?.message.includes("counter offer") ?`/intersted-hustlr-profile/${item?.taskProposalDetails?.hustlerId}/${item?.taskProposalDetails?.task}`:`/task/${item?.taskId?._id}` ,
                              // /intersted-hustlr-profile/${item.hustlerId}/${id}

                            }}
                            style={{ color: "black", textDecoration: "none", maxWidth: "85%" }}
                            state={{
                              id:
                                item.message.includes("proposal") ||
                                  (item.message.includes("counter offer") &&
                                    item?.taskId?.status === "open")
                                  ? "new-jobs"
                                  : item.message.includes("review")
                                    ? "completed-task"
                                    : "in-progress",
                            }}
                          >
                            <span className="dropdown-item ">
                              {item?.message?.length > 80 ? item.message.substring(0, 80) + '...' : item.message}
                            </span>
                          </Link>
                        </li>                          
                      )}
                    </>
                  ))}
                </>
              ) :
              
              (


                <>
                
{/* //========================================================================================= */}
{/* {isTourRunning?
<li>
      <div className="tooltip--task--details">
        {false ? (
          <FaCheckSquare
            data-tooltip-id="task--detail-id"
            data-tooltip-content="Marked as Seen"
            style={{ color: "#ffffff" }}
          />
        ) : (
          <FaSquare
            data-tooltip-id="task--detail-id"
            data-tooltip-content="Mark Seen"
          />
        )}
      </div>
      <span className="dropdown-item page-five page-three-element">
        CREATR MALIK marked your job lawn mowing as completed
      </span>
    </li>
    :""
} */}
{/* //========================================================================================= */}





                  {allUnreadNotifications.map((item, index) => (
                    <>
                      {item?.message?.includes("message") ||
                        item?.message?.includes("image") ? (
                        <li key={item?.id || index} onClick={() => handleNotificationClick(item._id)}>
                          <Tooltip
                            id="task--detail-id"
                            className="tooltip--task--details"
                          />
                          {clickedItems.includes(item._id) ? (
                        <FaCheckSquare // Render the checkmark icon after one click
                            data-tooltip-id="task--detail-id"
                            data-tooltip-content="Marked as Seen"
                            style={{ color: '#fffff' }} // Change the color to green for a visual indicator
                          />
          ) : (

       <FaSquare
              data-tooltip-id="task--detail-id"
              data-tooltip-content="Mark Seen"
              onClick={() => handleNotificationClick(item._id)}
            />
          )}

                          <span
                            onClick={() => handleSendDirectorMessage(item)}
                            className="dropdown-item "
                          >
                            {item?.message?.length > 80 ? item.message.substring(0, 80) + '...' : item.message}
                          </span>
                        </li>

                      ) : (
                        <li key={item?.id || index} onClick={() => handleNotificationClick(item._id)}>
                          <Tooltip
                            id="task--detail-id"
                            className="tooltip--task--details"
                          />
           {clickedItems.includes(item._id) ? (
            
           <FaCheckSquare 
              data-tooltip-id="task--detail-id"
              data-tooltip-content="Marked as Seen"
              style={{ color: '#fffff' }} // Change the color to green for a visual indicator
            />
          ) : (

       <FaSquare
              data-tooltip-id="task--detail-id"
              data-tooltip-content="Mark Seen"
              onClick={() => handleNotificationClick(item._id)}
            />
          )}



                          <Link
                          
                            to={{
                              // pathname: `/job-details/${item?.taskId?._id}`,
                              pathname:
                              item?.message.includes("area")?`/hustlerhome`:

                                item.message.includes("counter offer") ||
                                  item.message.includes("assigned")
                                  ?
                                  "/hustlerhome"
                                  // navigate("/home", { state: { id: "incoming-jobs" } })
                                  // "/home"
                                  :item?.notificationType === "review"
                                   ? `/job-details/${item?.taskId?._id}`:
                                   `/job-details/${item?.taskId?._id}`,
                            }}
                            style={{ color: "black", textDecoration: "none", maxWidth: "85%" }}
                            // state={{
                            //   id:
                            //     item.message.includes("counter offer") &&
                            //       item?.taskId?.status === "open"
                            //       ? "interested-jobs":"interested-jobs"
                            // }}

                            state={{
                              id:
                                item.message.includes("counter offer") &&
                                  item?.taskId?.status === "open"
                                  
                                  ? "interested-jobs"
                                  : item.message.includes("counter offer") ||
                                    (item.message.includes("assigned") &&
                                      item?.taskId?.status === "assigned")
                                    ? "incoming-jobs"
                                    : item.message.includes("approved") ||
                                      item.message.includes("review")
                                      ? "completed-jobs"
                                      : "upcoming",
                            }}

                          >
                            <span className="dropdown-item ">
                              {/* {item?.message} */}
                             {item?.message?.length > 80 ? item.message.substring(0, 80) + '...' : item.message}
                            </span>
                          </Link>
                        </li>
                      )}
                    </>
                  ))}
                </>

              )
              
              }
            </div>

            {allUnreadNotifications?.length > 0 ? (
              <Link
                to="/all-notifications"
                className="dropdown-footer clear-notifications"
              >
                {/* <span onClick={clearAllNotification}>Clear All</span> */}
                <span>View All</span>
              </Link>
            ) : (
              <Link
                to="/all-notifications"
                className="dropdown-footer clear-notifications"
                style={{ borderTop: "0px" }}
              >
                {/* <span onClick={clearAllNotification}>Clear All</span> */}
                <span>View All Notifications</span>
              </Link>
            )}
          </ul>
        </div>
      )}
    </>
  );
};

export default Notifications;

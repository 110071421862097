import React from "react";
import { AiOutlineCalendar } from "react-icons/ai";
import { GrLocation } from "react-icons/gr";
import { IoClose } from "react-icons/io5";
import { TbMoneybag } from "react-icons/tb";
import { setReportFormatDate } from "../assets/Helper";
import { BudgetFormat } from "../utils/helper";

const ChatIdTask = ({
  setshowChatIdModel,
  taskDetails,
  setshowTaskDetailsModel,
  text,
}) => {
  return (
    <div className="director--taskcomment--modal custom--scroll">
      <div className="custom--container" style={{maxHeight:'86vh'}}>

        <div className="mb-0">
          <div className="comment--items--header mb-4">
            <div className="comment--items--header--title">
              <h2>{taskDetails?.title}</h2>
              <div className="service--category--task">
                {/* {taskDetails.serviceCategories &&
                  taskDetails.serviceCategories.map((item) => (
                    <h3>{item}</h3>
                  ))} */}
                  {taskDetails?.serviceName && (<h3>{taskDetails?.serviceName}</h3>)}
              </div>
            </div>

            <div className="close--icons--comment">
              {text && text == "profile" ? (
                <div
                  className="close--icons"
                  onClick={() => setshowTaskDetailsModel(false)}
                >
                  <IoClose />
                </div>
              ) : (
                <div
                  className="close--icons"
                  onClick={() => setshowChatIdModel(false)}
                >
                  <IoClose />
                </div>
              )}
            </div>

          </div>
          <div className="comment--items--content">
            <p className="d-flex">
              <span className="comment--items--content--icon">
                <AiOutlineCalendar />
              </span>
             {/* { taskDetails?.endDate?
              setReportFormatDate(taskDetails?.dueDate) `to ${setReportFormatDate(taskDetails?.endDate)}` 
              :
              setReportFormatDate(taskDetails?.dueDate)  
             } */}

{taskDetails?.endDate
  ? `${setReportFormatDate(taskDetails?.dueDate)} to ${setReportFormatDate(taskDetails?.endDate)}`
  : setReportFormatDate(taskDetails?.dueDate)}

            </p>
           { taskDetails?.budget==0?
            <p className="d-flex">
              <span className="comment--items--content--icon">
                <TbMoneybag />
              </span>{" "}
              Budget: open to offer 
            </p>
            :
                        <p className="d-flex">
                        <span className="comment--items--content--icon">
                          <TbMoneybag />
                        </span>{" "}
                        Budget: ${BudgetFormat(`${taskDetails?.budget}`)}
                      </p>
}          
            <p className="d-flex">
              <span className="comment--items--content--icon">
                <GrLocation />
              </span>{" "}
              Area: {taskDetails?.cityName}
            </p>
          </div>
          <div className="comment--items-footer custom--scroll--thumb" style={{overflow:'auto',maxHeight:'50vh',paddingRight:'10px'}}>
           
              {taskDetails?.description}

          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatIdTask;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";

import DirectorSignup from "./DirectorSignup";
import HustlerSignup from "./HustlerSignup";
import { withoutAuthAxios } from "../../services/config";
import { saveServices, saveTaskOptions ,CombineTaskOptions} from "../../Redux/Reducers/appSlice";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../common/IsLoadingHOC";
function SignUp(props) {
    const userType = useSelector((state) => state.auth?.user?.userType);
  
  const location = useLocation();
  const id = location?.state?.id;
  console.log("singuou",id )
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.accessToken);
  const { setLoading } = props;
  const [toggleActiveForm, setToggleActiveForm] = useState("hustler");
  
  const activeFormBtn = (item) => {
    setToggleActiveForm(item);
  };

  const getTaskListData = async () => {
    setLoading(true);
    try {
      const response = await withoutAuthAxios().get(`/services/get-all-services`);
      const resData = response?.data;

      if (resData?.status === 1) {
        const servicesMap = new Map();
        const categoriesMap = new Map();

        resData?.data?.forEach((item) => {
          if (item?.serviceName) {
            const normalizedServiceName = String(item?.serviceName).trim().toLowerCase();

            servicesMap.set(normalizedServiceName, {
              label: item?.serviceName,
              value: item?.serviceName,
            });
          }
          item?.category?.forEach((cat) => {
            if (cat?.value) {
              const normalizedCategoryName = String(cat.value).trim().toLowerCase();
              // Use the normalized name as the key
              categoriesMap.set(normalizedCategoryName, {
                label: cat.value,
                value: cat.value,
              });
            }
          });
        });

        const servicesArray = Array.from(servicesMap.values());
        const categoriesArray = Array.from(categoriesMap.values());

        servicesArray.sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));
        categoriesArray.sort((a, b) => a.label.localeCompare(b.label, undefined, { sensitivity: 'base' }));

        dispatch(saveServices(servicesArray));
        dispatch(saveTaskOptions(categoriesArray));
      } else {
        console.error("Error status in response:", resData?.message);
        toast.error(resData?.message || "An unknown error occurred");
      }
    } catch (error) {
      console.error("Error occurred:", error?.message);
      toast.error(error?.response?.data?.message || error?.message || "An unknown error occurred");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
  //  getTaskListData();
    if (token) {
      // alert("hi")
      // navigate("/home");
      const destination =
      userType === "DIRECTOR"
        ? "/creatrhome"
        : userType === "HUSTLER"
        ? "/hustlerhome"
        : "/home";
        
        navigate(destination);


    }
  }, []);

  useEffect(() => {
    setToggleActiveForm(id || "hustler");
  }, [id]);

  return (
    <>
      <div className="login--container">
        <div className="signup-form">
          <Link to='/' className="text-center">
            <img
              src="./assets/images/logo.png"
              class="mb-3 w-full logo--form"
              alt="logo"
            />
          </Link>

          <div
            // className={`form--main--container ${toggleActiveForm === "hustler" ? "right-panel-active" : ""
            //   }`}
            id="container"
          >
            {id=="hustler"?
            <HustlerSignup />:
            <DirectorSignup />}
            {/* <div className="overlay-container">
              <div className="overlay">
                <div className="overlay-panel overlay-left">
                  <h2>Become a Creatr</h2>
                  <p className="wordkeep">
                    Tired of high contractor costs? SideHustl connects you with the perfect fit for odd jobs.
                  </p>
                  <button
                    className="btn-dark btn darkbtn-hover custom--btn"
                    id="signIn"
                    onClick={() => activeFormBtn("director")}
                  >
                    <span>Click here</span>
                  </button>
                </div>

                <div className="overlay-panel overlay-right">
                  <h2>Become a Hustlr</h2>
                  <p className="wordkeep">
                    Seeking extra income or independence? Bid on local jobs with SideHustl, connecting you effortlessly.
                  </p>
                  <button
                    className="btn-dark btn darkbtn-hover custom--btn"
                    id="signUp"
                    onClick={() => activeFormBtn("hustler")}
                  >
                    <span>Click here</span>
                  </button>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default IsLoadingHOC(SignUp);

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AiOutlineCalendar, AiOutlineHeart } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GrLocation } from "react-icons/gr";
import { IoCloseSharp, IoWarningOutline } from "react-icons/io5";
import { TbMoneybag } from "react-icons/tb";
import Select from "react-select";
import { authAxios } from "../../services/config";
import { toast } from "react-toastify";
import { setReportFormatDate } from "../../assets/Helper";
import { GoStar, GoStarFill } from "react-icons/go";
import { useSocket } from "../../contexts/SocketContext";
import {
  BudgetFormat,
  displayBudget,
  handleDecimalNumber,
  payloadBudget,
} from "../../utils/helper";
import TaskMedia from "../../common/TaskMedia";
import RattingStar from "../../common/RattingStar";
import DatePicker from "react-datepicker";
import { useDispatch } from "react-redux";
import { setInterestTask } from "../../Redux/Reducers/appSlice";

const ShowInterestModal = ({
  taskid,
  setShowInterestModal,
  setLoading,
  TaskDetails,
  fetchappliedJobs,
  setcurrentPage,
  ongoingPagination,
  setongoingPagination,
  fetchAlltask,
  setActiveTab,
  handleTabClick,
  handleNavigateTabClick,
  fetchWishlisttask,
  fetchhustlerDetails,
  prevIntrestedTask,
}) => {
  const { user } = useSelector((state) => state.auth);
  const location = useLocation();
  const socket = useSocket();
  const [startDate, setStartDate] = useState(new Date());
  const [confirmStripeAccount, setConfirmStripeAccount] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [stripeStatus, setstripeStatus] = useState(true)

  const [data, setdata] = useState({
    amount: "",
    description: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setdata((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };


  const fetchUserAllReviewDetails = async () => {
    await authAxios()
      .get(`/users/get-specific-user/${user.userId}`)
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          setstripeStatus(resData.data.isStripeAccountCreated)

         
          //  setallProfile(resData?.data);
        } else {
          toast.error(resData.message);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  useEffect(() => {
    setStartDate(new Date(TaskDetails?.dueDate));

    const { amount, description, proposalDate, taskId } = prevIntrestedTask;
    const getTaskIdFromUrl = () => {
      const searchParams = new URLSearchParams(location.search);
      const taskIdFromUrl = searchParams.get("taskId");
      if (taskIdFromUrl && taskId && taskIdFromUrl == taskId) {
        setdata({ amount: amount, description: description });
        setStartDate(new Date(proposalDate));
      }
    };
    getTaskIdFromUrl();

  }, [prevIntrestedTask, location.search]);

  useEffect(()=>{
    fetchUserAllReviewDetails()
  },[])

  const handleStripeAccountConnect = async () => {
    const { amount, description } = data;
    const payload = {
      taskId: taskid,
      amount: amount,
      description: description,
      proposalDate: startDate,
    };

    dispatch(setInterestTask(payload));

    setLoading(true);
    await authAxios()
      .post(`/stripe/create-express-account`, { taskId: taskid })
      .then((response) => {
        const resData = response.data;
        if (resData.status == 1) {
          window.open(resData?.data?.url);
          setLoading(false);
        } else {
          toast.error(resData.message);
          setLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { amount, description } = data;
    // if (amount && description) {
    // Check if amount and description are present

    if(stripeStatus==true){
      const result = displayBudget(amount);
      const payload = {
        task: taskid,
        offer: payloadBudget(`${amount}`),
        description: description,
        proposalDate: startDate,
      };

      setLoading(true);

      try {
        const response = await authAxios().post(
          "/taskproposal/create",
          payload
        );
        const responseData = response.data;
        setLoading(false);
        if (responseData.status === 1) {
          socket.emit("applyForTask", responseData.data);
          toast.success(responseData.message);
          setShowInterestModal(false);
          // fetchappliedJobs(1);
          // setcurrentPage(1);
          // setActiveTab("interested-jobs");
          // setongoingPagination("interested-jobs");
          handleNavigateTabClick("interested-jobs")
          fetchhustlerDetails();
          // navigate("/home");
          navigate("/hustlerhome");
          dispatch(setInterestTask(null));
        } else {
          toast.error(responseData.message);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.message);
      }
    }else{
      const resData = await authAxios()
      .get(`/stripe/check-stripe-account`)
      .then((response) => response.data)
      .catch((error) => {
        if (error.response.data.status === 0 &&stripeStatus==false ) {
          // handleStripeAccountConnect();
          setConfirmStripeAccount(true);
        } else {
          toast.error(error.response.data.message);
        }
      });
    }

    

    // if (resData && resData.status === 1) {
    //   // If the user has a Stripe account, proceed with submission
      
    // }
    // } else {
    //   toast.error("Please fill in both amount and description fields.");
    // }
  };

  const  handleCloseModal = () => {
    setShowInterestModal(false);
    dispatch(setInterestTask(null));
  }

  // useEffect(()=>{
  //  if( confirmStripeAccount === false){
  //   if(new Date(TaskDetails?.dueDate)>new Date())
  //   setStartDate(new Date(TaskDetails?.dueDate));
  //  }
  //  else{
  //       setStartDate(new Date());
  //  }
  // },[])

  // const [startDate, setStartDate] = useState(new Date());
  const [isDateEditable, setIsDateEditable] = useState(true);

  useEffect(() => {
    if (confirmStripeAccount === false) {
      if (new Date(TaskDetails?.dueDate) > new Date()) {
        setStartDate(new Date(TaskDetails?.dueDate));
        
        setIsDateEditable(false); // Disable editing if condition is true
      } else {
        setStartDate(new Date());

        setIsDateEditable(true);
      }
    } else {
      setStartDate(new Date());
      setIsDateEditable(true);
    }
  }, [TaskDetails?.dueDate, confirmStripeAccount]);


  return (
    <>
      {confirmStripeAccount === true ? (
        <>
          <div className="stipe--modal--container">
            <div className="stipe--modal--box">
              <h2>Required verification</h2>
              <p>
                Verification for your Stripe account is essential for security
                and compliance. It involves confirming your identity and
                providing necessary documents, ensuring trust and transparency
                on the platform.
              </p>

              <div className="modal-footer">
                <button
                  type="button"
                  className="custom--btn ligghtbtn-hover transparent--btn mini-btn"
                  onClick={() => setConfirmStripeAccount(false)}
                >
                  <span>Cancel</span>
                </button>
                <button
                  type="button"
                  className="custom--btn darkbtn-hover mini-btn"
                  onClick={handleStripeAccountConnect}
                >
                  <span>Connect</span>
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="show--interest--modal--overlay">
          <div className="show--interest--modal custom--scroll showinterest--container">
            <div className="showInsterest--header">
              <h2 className="playFairDisplay heading-h3">Show Interest</h2>
              <div
                className="close--popup"
                onClick={handleCloseModal}
              >
                <IoCloseSharp />
              </div>
            </div>

            <form
              onSubmit={handleSubmit}
              className="showInsterest--content--area"
            >
              <div className="showInsterest--content--area--left">
                {/* <div className="head--title--intersted">
                <h2 className="playFairDisplay heading-h3">Show Interest</h2>
              </div> */}
                <div className="category--lists">
                  {/* <ul>
                  {TaskDetails?.serviceCategories.map((item, index) => (
                    <>
                      <li key={index}>{item}</li>
                    </>
                  ))}
                </ul> */}
                  <ul>
                    <li>{TaskDetails?.serviceName}</li>
                  </ul>
                </div>

                <div className="task--budget--info">

               {TaskDetails?.budget === 0 ?
                <p>
  <TbMoneybag /> Budget: Open to Offer
</p>
:
<p>
<TbMoneybag /> Budget:$ {BudgetFormat(`${TaskDetails?.budget}`)}
</p>
}
 
                  <p>
                    <AiOutlineCalendar /> Date Range:{" "}
                    {/* {setReportFormatDate(TaskDetails?.dueDate)} */}
                    { TaskDetails?.endDate?
                             (
                              <> {setReportFormatDate(TaskDetails?.dueDate)} to  {setReportFormatDate(TaskDetails?.endDate)}</>)
                             :(setReportFormatDate(TaskDetails?.dueDate))
}

                  </p>
                  <p>
                    <GrLocation /> Area: {TaskDetails?.cityName}
                  </p>
                </div>

                <div className="task--media">
                  <TaskMedia imagePaths={TaskDetails?.attachments} />
                </div>

                <div className="task--description--info">
                  <p>
                    <b>Job Description:</b>
                  </p>
                  <p>{TaskDetails?.description}</p>
                </div>

                <div className="showInsterest--form">
                  <h3>Submit Your Details</h3>

                  <div className="form--budget--input">
                    <div className="info--budget--label">
                      <p>
                        Your Offer Amount{" "}
                        <span className="mendotry-field">*</span>
                      </p>
                      <p className="mb-1">
                        <small>Share your job proposal with amount </small>
                      </p>
                      <div className="input--dolldar">
                        <span className="dollar-icon">$</span>
                        <input
                          required
                          type="text"
                          onChange={handleChange}
                          name="amount"
                          value={displayBudget(data.amount)}
                          placeholder="0"
                          className="ammout--task"
                        />
                      </div>
                    </div>
                    { new Date(TaskDetails?.endDate)>new Date()?
                    <div
                      className="input--budget--field"
                      style={{ zIndex: "2" }}
                    >
                      <p>Enter Your Job Date{" "}
                      <span className="mendotry-field">*</span>

                      </p>
                      <DatePicker
                        className="form-control mt-1"
                        minDate={new Date()}
                        // maxDate={new Date(TaskDetails?.endDate)}  

                        selected={startDate}
                        // selected={new Date()}

                        onChange={(date) => setStartDate(date)}
                        dateFormat="MMM-dd-yy"
                        // readOnly={!isDateEditable} // Make the DatePicker readonly if not editable

                        required
                      />
                    </div>
                    :
                    <div
                    className="input--budget--field"
                    style={{ zIndex: "2" }}
                  >
                    <p>Enter Your Job Date{" "}
                    <span className="mendotry-field">*</span>
                    </p>
                    <DatePicker
                      className="form-control mt-1"
                      minDate={new Date()}
                      // maxDate={new Date(TaskDetails?.endDate)}  

                      selected={startDate}
                      // selected={new Date()}

                      onChange={(date) => setStartDate(date)}
                      dateFormat="MMM-dd-yy"
                      // readOnly={!isDateEditable} // Make the DatePicker readonly if not editable

                      required
                    />
                  </div>
}

                  </div>

                  <div className="showInsterest--textarea">
                    <h3>Would you like to add a comment?</h3>
                    <textarea
                      onChange={handleChange}
                      name="description"
                      value={data.description}
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    className="custom--btn ligghtbtn-hover transparent--btn minbutton mt-2"
                  >
                    <span>Show Interest</span>
                  </button>
                </div>
              </div>
              {/* end showInsterest--content--area--left */}
              <div className="showInsterest--content--area--right">
                <div className="submit--task--showInsterest">
                  {/* <div className="task--items--edit--work hidedown-991">
                  <div className="edit--taks--items">
                    <button
                      type="submit"
                      className="custom--btn ligghtbtn-hover transparent--btn"
                    >
                      <span>Show Interest</span>
                    </button>
                  </div>
                </div> */}
                  <div className="application--huster">
                    <p>
                      <img
                        src="/assets/images/applications.png"
                        width={22}
                        height={22}
                      />{" "}
                      Applications : {TaskDetails?.applications}
                    </p>
                    {/* 
                  <p>
                    <img
                      src="/assets/images/low-price.png"
                      width={22}
                      height={22}
                    />{" "}
                    Lowest-Price: $25{" "}
                  </p>
                  */}
                  </div>

                  <div className="director--reviews border--t-b">
                    <p className="director--reviews--heading">
                      Creatr’s Reviews
                    </p>
                    <div className="star--rating--director">
                      <RattingStar
                        ratings={TaskDetails?.user?.directorDetails?.ratings}
                      />
                    </div>
                    <p className="rating--director--outof">
                      {handleDecimalNumber(
                        TaskDetails?.user?.directorDetails?.ratings
                      )}{" "}
                      out of 5
                    </p>
                    <div className="director--info">
                      <p>Creatr’s Professionalism</p>
                      <small>
                        {handleDecimalNumber(
                          TaskDetails?.user?.directorDetails?.behaviorAvg
                        )}
                      </small>
                    </div>

                    <div className="director--info">
                      <p>Creatr’s Payment</p>
                      <small>
                        {handleDecimalNumber(
                          TaskDetails?.user?.directorDetails?.paymentAvg
                        )}
                      </small>
                    </div>
                  </div>

                  <div className="director--reviews">
                    <div className="director--info">
                      <p>Total Jobs</p>
                      <small>
                        {TaskDetails?.user?.directorDetails?.totalTask}
                      </small>
                    </div>

                    <div className="director--info">
                      <p>Completed Jobs</p>
                      <small>
                        {TaskDetails?.user?.directorDetails?.completedTask}
                      </small>
                    </div>

                    <div className="director--info">
                      <p>Cancel Jobs</p>
                      <small>
                        {TaskDetails?.user?.directorDetails?.closedTask}
                      </small>
                    </div>
                  </div>
                </div>

                <div className="profile--info">
                  <div className="name--profile">
                    <div className="profile--img">
                      <Link to={`/user-info/${TaskDetails?.user?._id}`}>
                        <img
                          src={`${process.env.REACT_APP_IMAGE_URL}profile_image/${TaskDetails?.user?.profilePic}`}
                          height={80}
                          width={80}
                          alt=""
                        />
                      </Link>

                      <div className="profile--infodetails">
                        <small>Name</small>
                        <p>
                          {TaskDetails?.user?.fname} {TaskDetails?.user?.lname}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="profile--since">
                    <p>
                      Member Since{" "}
                      {setReportFormatDate(TaskDetails?.user?.createdAt)}
                    </p>
                  </div>
                </div>
              </div>
              {/* end showInsterest--content--area--right */}
            </form>
          </div>
        </div>
      )}
    </>
  );
};

export default ShowInterestModal;
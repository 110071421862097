import React from 'react';
import { PDFDownloadLink } from "@react-pdf/renderer";
import { IoCloudDownloadOutline } from "react-icons/io5";
import PaymentInvoicePdf from './PaymentInvoicePdf';
import { dateFormat, dateFormatWithMilli, payloadBudget } from '../../utils/helper';


const PaymentInvoice = () => {

    const paymentDetails=JSON.parse(sessionStorage.getItem('paymentDetails'))
    // console.log(paymentDetails,"paymentDetails")
    const FinalBid=sessionStorage.getItem("FinalBid")

    return (
        <div style={styles.container} className='director--task--lists pt-117'>
            <div style={styles.card}>
                <div style={styles.header}>
                    <div style={styles.headerContent}>
                        <a target="_blank" rel="noreferrer">
                            <img
                                alt="Logo"
                                src="https://stripe-images.s3.amazonaws.com/emails/acct_1OhFFXF3HwezfEYa/5/twelve_degree_icon@2x.png"
                                style={styles.logo}
                            />
                        </a>
                    </div>
                </div>
                <div style={styles.titleContainer}>
                    <h2 style={styles.title}>Receipt from SIDEHUSTL</h2>
                </div>
                <div style={styles.paymentInfo}>
                    <div style={styles.infoRow}>
                        <div style={styles.infoBlock}>
                            <span style={styles.label}>Amount paid</span>
                            <span>${(paymentDetails?.payment?.amount / 100)?.toFixed(2)}</span>
                        </div>
                        <div style={styles.infoBlock}>
                            <span style={styles.label}>Date</span>
                            <span>{dateFormatWithMilli(paymentDetails?.payment?.created)}</span>
                        </div>
                        <div style={styles.infoBlock}>
                            <span style={styles.label}>Card Details</span>
                            xxxx-xxxx-xxxx-{paymentDetails.payment?.payment_method_details?.card?.last4}
                        </div>
                        
                    </div>
                </div>
                <div style={styles.summary}>
                    <h3 style={styles.summaryTitle}>Summary</h3>
                    <div style={styles.summaryContent}>
                        <div style={styles.summaryRow}>
                            <span>Payment to SIDEHUSTL</span>
                            <span> ${FinalBid}</span>
                        </div>
                        <div style={styles.summaryRowBold}>
                            <span>Service fee </span>
                            {/* <span>${payloadBudget(`${ (0.18) * FinalBid}`)}</span> */}
                            {/* <span>${payloadBudget(`${ paymentDetails?.task?.finalPrice- FinalBid}`)}</span> */}
                            <span>${payloadBudget(`${(Number(paymentDetails?.task?.stripePaymentAmount) - Number(FinalBid)).toFixed(2)}`)}</span>



                        </div>
                        <div style={styles.summaryRowBold}>
                            <span>Amount charged</span>
                            <span>${(paymentDetails?.payment?.amount_captured / 100)?.toFixed(2)}</span>
                        </div>
                        
                    </div>
                </div>
                <div style={styles.contact}>
                    <p>
                        If you have any questions, contact us at{' '}
                        <a href="mailto:admin@sidehustl.ca" style={styles.contactLink}>
                            admin@sidehustl.ca
                        </a>
                    </p>
                </div>
                <div style={styles.downloadButtonContainer}>
                    <PDFDownloadLink
                        document={<PaymentInvoicePdf paymentDetails={paymentDetails} FinalBid={FinalBid} />}
                        fileName="Payment_Invoice"
                        style={{textDecoration:'none'}}
                    >
                        {({ loading }) =>
                            loading ? (
                                "Loading..."
                            ) : (
                                <button style={styles.downloadButton}>
                                    Download Invoice
                                    <span style={styles.icon}>
                                        <IoCloudDownloadOutline />
                                    </span>
                                </button>
                            )
                        }
                    </PDFDownloadLink>
                </div>
            </div>
        </div>
    );
};


const styles = {
    container: {
        backgroundColor: '#f3f4f6',
        paddingBottom:50,
    },
    card: {
        backgroundColor: '#ffffff',
        paddingBottom: '20px',
        borderRadius: '10px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
        width: '95%',
        maxWidth: '800px',
        margin:'0 auto'
    },
    header: {
        backgroundColor: '#000000',
        borderTopLeftRadius: '10px',
        borderTopRightRadius: '10px',
    },
    headerContent: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '16px',
    },
    logo: {
        width: '40px',
    },
    titleContainer: {
        textAlign: 'center',
        padding: '40px 16px 20px',
    },
    title: {
        fontSize: '24px',
        fontWeight: 'bold',
        color: '#374151',
    },
    paymentInfo: {
        padding: '24px 16px',
    },
    infoRow: {
        display: 'flex',
        flexDirection: 'row',
        gap: '12px',
        marginBottom: '16px',
        justifyContent:'space-between',
    },
    infoBlock: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        fontWeight: 'bold',
        color: '#374151',
    },
    summary: {
        padding: '24px 16px',
    },
    summaryTitle: {
        fontSize: '12px',
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: '#6b7280',
        paddingBottom: '10px',
    },
    summaryContent: {
        backgroundColor: '#f3f4f6',
        borderRadius: '10px',
        padding: '16px',
    },
    summaryRow: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#374151',
    },
    summaryRowBold: {
        display: 'flex',
        justifyContent: 'space-between',
        color: '#374151',
        fontWeight: 'bold',
        marginTop: '16px',
    },
    contact: {
        padding: '24px 16px',
        color: '#374151',
        fontSize: '14px',
    },
    contactLink: {
        color: '#000000',
        fontWeight: 'bold',
        textDecoration: 'none',
    },
    downloadButtonContainer: {
        marginBottom: '16px',
        marginTop: '12px',
        display: 'flex',
        justifyContent: 'center',
    },
    downloadButton: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
        padding: '10px 20px',
        borderRadius: '8px',
        backgroundColor: '#000000',
        color: '#ffffff',
        border: 'none',
        cursor: 'pointer',
        transition: 'background-color 0.3s',
    },
    icon: {
        marginLeft: '8px',
        fontSize: '18px',
    },
};

export default PaymentInvoice;
import React, { useState } from 'react'
import Select from 'react-select';
import { CanadianCity } from '../../utils/constants'
import { useSelector } from 'react-redux';
import WhyHustler from '../../component/WhyHustler';
import { Link, useNavigate } from "react-router-dom";
// import Metadata from "../../components/Metadata";
import Metadata from "../../component/Metadata"
import metadataConfig from '../../config/metadataConfig';
const BecomeHustlerSignUp = () => {
  const navigate = useNavigate();
  const { title, description, keywords } = metadataConfig.beforeHustlrPage;

    const [selectedCity, setSelectedCity] = useState(null);
    const [selectedService, setSelectedService] = useState(null);
    const taskOptions = useSelector((state) => state.app.tasks);
    //===================
    const [provinceList, setprovinceList] = useState([]);
    const [cityList, setcityList] = useState([]);
    const [selectedProvince, setselectedProvince] = useState(null);
    const [stateCode, setStateCode] = useState("");

    const handleSelectProvince = (e) => {
      if (e) {
        setStateCode(e?.value);
        setselectedProvince(e);
        setSelectedCity([]);
      } else {
        setcityList([]);
        setSelectedCity([]);
        setStateCode("");
        setselectedProvince([]);
      }
    };
  


    const handleSelectCity = (option) => {
        setSelectedCity(option)
    }


    const handleSelectTask = (option) => {
        setSelectedService(option)
    }

    const howWorks = [
        {
            id: 1,
            name: "Build Your Profile",
            description: "Build your profile by selecting the type and location of jobs you want."
        },
        // {
        //     id: 2,
        //     name: " Get Verified",
        //     description: "Opt for Certn verification with just a few simple steps to showcase your credibility."
        // },
        {
            id: 2,
            name: " Accept Or Apply ",
            description: "Accept jobs that are assigned to you, or simply bid on jobs nearby."
        },
        // {
        //     id: 4,
        //     name: "Pay registration fee",
        //     description: "In applicable cities, we charge a $25 registration fee that helps us provide the best service to you."
        // },
        // {
        //     id: 5,
        //     name: "Set your schedule and area",
        //     description: "Set your weekly availability and opt in to receive same-day jobs."
        // },
        {
            id: 3,
            name: "Manage Your Tasks",
            description: "Schedule, chat, get paid, reviewed and even re-hired through one reliable platform."
        },
    ];

    return (

        <div id='becomeTasker--form' className='inner--page'>
      <Metadata title={title} description={description} keywords={keywords} />

         <style>
            {`

             .More_hustl_slog {
                   margin-top: 100px; /* Corrected property name */

             }
.hustler_head_title {
  margin-bottom: 40px;
  margin-top: 100px;
}
              @media only screen and (max-width: 768px) {
                .More_hustl_slog {
                 margin-top: 50px; /* Corrected property name */
            }
            .common--top--banner {
                min-height: clamp(420px, 58vw, 800px);
              }  
                .hustler_head_title {
          margin-bottom: 40px;
  margin-top: 40px;

  }
            `}
          </style>

            
            {/* <Header /> */}


            <section className='common--top--banner pt-117  bg--image--new' data-aos="fade-up" data-aos-duration="1000"           style={{ zIndex: "2"}}
            >
              <div style={{ zIndex: "4" }}>
            <h1
                className="playFairDisplay text-white text-center heading-h1 More_hustl_slog"
                data-aos="fade-up"
                data-aos-duration="800"
                // style={{marginTop:"100px"}}
                // style={{ zIndex: "4" }}
              >
                Less Hassle, More Hustl
              </h1>
              </div>

            {/* <h1 className='playFairDisplay  text-center text-white banner--content mt-50' style={{ fontSize: '55px' }}>Less Hassle, More Hustl</h1> */}


                <div
                //  className='hustler--box' 
                // style={{ zIndex: "2" }} 
                >

                    <div className='hustler--box--content'>
                    <h5
                className="playFairDisplay text-center text-white hustler_head_title"
                //  style={{ marginBottom: "40px",marginTop:"100px" }} 

              >
                {/* Search Your Job Here */}
                Find The Jobs You Want, Where You Want

              </h5>

              <Link 
                    style={{ display: "block", textAlign: "center",justifyContent:'center'}}
                     to="/signup" 
                     className='custom--btn  ligghtbtn-hover  flex mx-auto '
                     state={{ id: "hustler" }}

                    >
                        <span>Sign Up</span>
                    </Link>


                        {/* <h1 className='playFairDisplay heading-h1'>Embrace the freedom. <br />Be the architect of your own career.</h1> */}
                        {/* <h1 className='playFairDisplay heading-h1' >Find The Jobs You Want, Where You Want</h1> */}

                        {/* <Link className="btn  ligghtbtn-hover" to="/signup"><span>Sign Up</span></Link> */}
                    </div>
                </div>
                
                {/*===========*/} 
{/* <div onClick={() => navigate("/login")}>
                <div
                  className="search--bar--banner flex justify-center sm-flex-col sm-justify-start"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-duration="1600"
                >
                  <div className="need--select">
                    <input
                      required
                      placeholder="I need help with..."
                      className="need--select custom--input"
                      value={selectedService}

                      onChange={(e) => setSelectedService(e.target.value)}
                    />

                  </div>
                  <div className="city--zip">
                    <Select
                      options={provinceList}
                      isClearable
                      className="select--custom province--select"
                      placeholder={`Select Province`}
                      value={selectedProvince}
                      onChange={handleSelectProvince}
                    // isSearchable={false}
                    />
                  </div>

                  <div className="city--zip">
                    <Select
                      options={cityList}
                      isClearable
                      className="select--custom"
                      placeholder={`Select City`}
                      value={selectedCity}
                      onChange={handleSelectCity}
                    // isSearchable={false}
                    />
                  </div>

                  {selectedService && selectedCity?.label ? (
                    <button
                      className="btn btn-dark submit--need"
                      onClick={() => navigate("/login")}
                    >
                      Get help today
                    </button>
                  ) : (
                    <button
                      className="btn btn-dark submit--need disabled"
                      onClick={() => navigate("/login")}
                      disabled
                    >
                      Get help today
                    </button>
                  )}
                </div>

               <div
                  className="category--searchlist"
                  data-aos="fade-up"
                  data-aos-offset="300"
                  data-aos-duration="1200"
                >
                                      <Link to="/signup"         state={{ id: "director" }}
 className='custom--btn darkbtn-hover flex mx-auto mt-5' style={{
                        justifyContent:'center'
                    }}>
                        <span>Create Job</span>
                    </Link>

                </div>


              </div> */}
{/*============*/} 
<ul className="bubble--container">
          <img
            src="./assets/images/banner--image/img-1.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-2.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-4.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-3.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-5.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-6.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-7.png"
            class="bubble"
            alt="img-2"
          />
          <img
            src="./assets/images/banner--image/img-8.png"
            class="bubble"
            alt="img-2"
          />
        </ul>

            </section>

{/* ========================== */}
<section className="py-50 pt-0 px-3 " style={{background:"#f8f9fa"  }} >
                <div className='custom--container m-auto px-0' style={{ paddingTop:"40px"}}>
                    <h2 className='playFairDisplay heading-h2 text-center' data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">
                      {/* How to SideHustl? */}
                      How it Works?   

                      </h2>
                    <div className='how--works--grid' data-aos="fade-up" data-aos-delay="100" data-aos-duration="1300">
                        {howWorks.map(item => (
                            <div style={{minHeight: "10px", padding: "10px 10px"}} className='grid--how' key={item.id}>
                                <h3><span className='number--grid'>{item.id}</span>{item.name}</h3>
                                <p>{item.description}</p>
                            </div>
                        ))}
                    </div>
                    <h3 className='playFairDisplay heading-h2 text-center' data-aos="fade-up"
                    //  data-aos-delay="100" 
                     data-aos-duration="800"
                     >      
                     Start earning in just a few clicks. 

                      {/* Create an account and  */}
                      {/* Offload your to-do list in just a few clicks */}
                    </h3>

                    <Link 
                    style={{marginTop: "40px", display: "block", textAlign: "center",justifyContent:'center'}}
                     to="/signup" 
                     state={{ id: "hustler" }}
                     data-aos="fade-up"
                    //  data-aos-delay="100" 
                     data-aos-duration="800"

                     className='custom--btn darkbtn-hover flex mx-auto mt-25'

                    >
                        <span>Get Started</span>
                    </Link>

                </div>
            </section>
{/* ======================== */}




            {/* <section className="py-25 px-3">


                <div className='custom--container m-auto px-0 ' >
                    <h2 className='playFairDisplay heading-h2 text-center' data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000">How To SideHustl</h2>
                    <div
                     className='how--works--grid'
                      data-aos="fade-up" data-aos-delay="100" data-aos-duration="1000"
                >

                        {howWorks.map(item => (
                            <div style={{minHeight: "10px", padding: "10px 10px"}} className='grid--how' key={item.id}>
                                <h3><span className='number--grid'>{item.id}</span>{item.name}</h3>
                                <p>{item.description}</p>
                            </div>
                        ))}
                    </div>

                    <h3 className='playFairDisplay heading-h2 text-center' data-aos="fade-up" data-aos-delay="80" data-aos-duration="1000">  
                      Create an account and offload your to-do list in just a few clicks
                    </h3>


                    <Link style={{marginTop: "40px", display: "block", textAlign: "center",justifyContent:'center'}} to="/signup" className='custom--btn darkbtn-hover flex mx-auto mt-25'

>
    <span>Get Started</span>
</Link>


                </div>
            </section> */}

            <WhyHustler />
            {/* <section className="py-100" style={{ backgroundColor: "#f8f9fa" }}>
        <div className="custom--container m-auto text-center ">
          <h3
            className="playFairDisplay heading-h3"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
Hear that? Opportunity’s knocking. </h3>
<h3
            className="playFairDisplay heading-h3 mt-25"
            data-aos="fade-up"
            data-aos-duration="1000"
            
          >
Join The SideHustl Community 
</h3>

<div  
            data-aos-offset="300"
            data-aos-duration="1500"

style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
              marginTop: "15px",
              gap: "20px"  // Added gap between buttons

            }}>
          <Link
            to="/signup"
            className="custom--btn mt-25 darkbtn-hover"
            data-aos="fade-up"
            // data-aos-offset="300"
            // data-aos-duration="1500"
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   margin: "0 auto",
            //   marginTop: "15px",
            // }}
            // state={{ id: "director" }}
          >
                        <span>Become a Hustlr</span>

          </Link>
          <Link
            to="/signup"
            className="custom--btn mt-25 darkbtn-hover"
            data-aos="fade-up"
            // data-aos-offset="300"
            // data-aos-duration="1500"
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   margin: "0 auto",
            //   marginTop: "15px",
            // }}
            state={{ id: "director" }}
          >
                        <span>Become a Creatr</span>

          </Link>
          </div>

        </div>

      </section> */}

      <section className="py-100 pt-0 " style={{ backgroundColor: "#f8f9fa" }}>
        <div className="custom--container m-auto text-center"
                    data-aos="fade-up"
                    data-aos-offset="200"
        
                    data-aos-duration="1300"
        
        style={{paddingTop:"25px", marginTop:"25px"}}
        >
          <h3
            className="playFairDisplay heading-h3"
            data-aos="fade-up"
            data-aos-offset="200"

            data-aos-duration="1300"
          >
Hear that? Opportunity’s knocking.</h3>
{/* <h5
            className="playFairDisplay heading-h2 "
            data-aos="fade-up"
            data-aos-duration="1000"
            style={{marginTop:"25px", marginBottom:"25px"}}

          >
Join The SideHustl Community 
</h5> */}
<h3 className='playFairDisplay' 
data-aos="fade-up"
data-aos-offset="200"

data-aos-duration="1300"
style={{marginTop:"25px", fontSize: '28px', color: '#333', marginBottom:"25px"}}

> 

 Join The SideHustl Community
 </h3>


<div  
            data-aos="fade-up"
            data-aos-offset="200"

            data-aos-duration="1300"
            
style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "0 auto",
              marginTop: "15px",
              gap: "20px"  // Added gap between buttons

            }}>
          <Link
            to="/signup"
            className="custom--btn  darkbtn-hover"
            data-aos="fade-up"
            state={{ id: "hustler" }}
            // data-aos-offset="300"
            // data-aos-duration="1500"
            style={{
              // marginTop: "70px",
              marginBottom: "-20px"
            }}
          >
            <span>Become a Hustlr</span>
          </Link>

          <Link
            to="/signup"
            className="custom--btn  darkbtn-hover"
            data-aos="fade-up"
            // data-aos-offset="300"
            // data-aos-duration="1500"
            // style={{
            //   display: "flex",
            //   alignItems: "center",
            //   justifyContent: "center",
            //   margin: "0 auto",
            //   marginTop: "15px",
            // }}
            style={{
              marginBottom: "-20px"
            }}
            state={{ id: "director" }}
          >
            <span>Become a Creatr</span>
          </Link>
          </div>

        </div>
      </section>
            
        </div>
    )
}

export default BecomeHustlerSignUp